import React from 'react';

const StarIcon = () => {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0L16.3731 5.98091L23.4127 8.2918L19.0759 14.2991L19.0534 21.7082L12 19.44L4.94658 21.7082L4.92414 14.2991L0.587322 8.2918L7.62688 5.98091L12 0Z" fill="#FFC702"/>
        </svg>
    );
};

export default StarIcon;