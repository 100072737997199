import {useState,useCallback} from 'react';
import { ITask, INft } from "../types/global_types"
import { IProject } from './useCreateProject';

const useUpdateTask = (event:ITask) => {
    const [data,setData] = useState<ITask>(event)

    const inputsHandler = useCallback((value:string | File | IProject | INft,inputName?:string) => {
        if(inputName){
            setData({...data,[inputName]:value})
        }
    },[data])

    return {data,inputsHandler}
}

export default useUpdateTask