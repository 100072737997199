import React from 'react';

const SearchIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.66667 2C4.99266 2 2 4.99266 2 8.66667C2 12.3407 4.99266 15.3333 8.66667 15.3333C10.2643 15.3333 11.7316 14.766 12.8815 13.8242L16.862 17.8047C16.9234 17.8687 16.997 17.9198 17.0784 17.9549C17.1598 17.9901 17.2474 18.0087 17.3361 18.0096C17.4248 18.0105 17.5128 17.9937 17.5949 17.9602C17.6771 17.9267 17.7517 17.8771 17.8144 17.8144C17.8771 17.7517 17.9267 17.6771 17.9602 17.5949C17.9937 17.5128 18.0105 17.4248 18.0096 17.3361C18.0087 17.2474 17.9901 17.1598 17.9549 17.0784C17.9198 16.997 17.8687 16.9234 17.8047 16.862L13.8242 12.8815C14.766 11.7316 15.3333 10.2643 15.3333 8.66667C15.3333 4.99266 12.3407 2 8.66667 2ZM8.66667 3.33333C11.6201 3.33333 14 5.71325 14 8.66667C14 11.6201 11.6201 14 8.66667 14C5.71325 14 3.33333 11.6201 3.33333 8.66667C3.33333 5.71325 5.71325 3.33333 8.66667 3.33333Z" fill="#738094"/>
        </svg>
    );
};

export default SearchIcon;