import { FC } from "react"
import CreateCard from "../create/CreateCard"
import { CardsListWrapper } from "./styles"
import { IInfoItem } from "../../../../types/global_types"
import CreateButton from "../../../../common/button/create_button"

interface IProps {
    cardsKey:string
    items:Array<IInfoItem>
    cardsHandler:(value:any,key:string) => void
}

const CardsList : FC<IProps> = ({cardsKey,items,cardsHandler}) => {
    
    const cardInputHandler = (value:any,name:string,cardIndex:number) : void => {
        const updatedItems : Array<IInfoItem> = items.map((item:IInfoItem,itemIndex:number) => {
            if(itemIndex === cardIndex){
                return {...item,[name]:value}
            }   

            return item
        })

        cardsHandler(updatedItems,cardsKey)
    }

    const addCard = () : void => {
        cardsHandler([...items,{link:'',img:''}],cardsKey)
    }

    const removeCard = (id:number) : void => {
        const updatedItems : Array<IInfoItem> = items.filter((item:IInfoItem,itemIndex:number) => itemIndex !== id)

        cardsHandler(updatedItems,cardsKey)
    }

  return (
    <CardsListWrapper>
        {
            items.map((card:IInfoItem,index:number) => {
                return (
                    <CreateCard
                    key={index}
                    link={card.link}
                    img={card.img}
                    cardIndex={index}
                    onChange={cardInputHandler}
                    removeCard={removeCard}
                    />
                )
            })
        }
        <CreateButton
        width={'300px'}
        height={'300px'}
        onClick={addCard}
        />
    </CardsListWrapper>
  )
}

export default CardsList
