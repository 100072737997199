import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        background: '#04A584',
        border: 'none',
        display: 'flex',
        padding: '13px 10px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        color: '#FFFFFF',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',

        transition:'all 0.2s ease',

        '&:hover':{
            background: '#027d65',
        },
        '&:active':{
            opacity:'0.7'
        },
        '&:disabled':{
            background: '#04A584',
            color: '#FFFFFF',
            cursor:'not-allowed'
        },
        '&.decline-btn': {
            borderColor: '#FF5858',
            background: '#FF5858',
            color:'white',
            '&:hover':{
                opacity:0.7
            }
        },
    },
})