import { useState, FC } from 'react';
import Checkbox from '../checkbox';
import Typography from '../typography';
import { IActionsFilters } from '../../../pages/Confirming';
import upperCaseFirstLetter from '../../utils/upperCaseFirstLetter';
import {FilterIcon} from '../../../assets'
import {
    CheckboxWrapper, DropdownWrapper,
    FilterButton,
    FilterWrapper, Row,
} from './styles';

interface IProps {
    options:IActionsFilters
    onChange?:(value:any) => void 
}

const FilterByProps : FC<IProps> = ({onChange,options}) => {
    const [isOpen, setIsOpen] = useState(false)

    const filterInputsHandler = (name:'category' | 'page' | 'type',id:number) => {
        const updatedOptions : IActionsFilters 
        =
        {
            ...options,
            [name]:options[name].map((item:{name:string,isSelected:boolean},i:number) => {
                if(id === i){
                    return ({
                        ...item,
                        isSelected:!item.isSelected
                    })
                }
                
                return item
            })
        }
        onChange && onChange(updatedOptions)
    }
    
    return (
        <FilterWrapper >
            <FilterButton onClick={() => setIsOpen(state => !state)}>
                <FilterIcon fill="#00C099" />
                <Typography variant='p'>
                    Filters
                </Typography>
            </FilterButton>
            {isOpen && <DropdownWrapper>
                <Row>
                    <p>Action type</p>
                    <CheckboxWrapper>
                        {
                            options.type.map((item:{name:string,isSelected:boolean},index:number) => {
                                return (
                                    <Checkbox
                                    key={index}
                                    onChange={() => filterInputsHandler('type',index)}
                                    active={item.isSelected}
                                    labelValue={upperCaseFirstLetter(item.name)}
                                    />
                                )
                            })
                        }
                    </CheckboxWrapper>
                </Row>
                <Row>
                    <p>Category</p>
                    <CheckboxWrapper>
                        {
                            options.category.map((item:{name:string,isSelected:boolean},index:number) => {
                                return (
                                    <Checkbox
                                    key={index}
                                    onChange={() => filterInputsHandler('category',index)}
                                    active={item.isSelected}
                                    labelValue={upperCaseFirstLetter(item.name)}
                                    />
                                )
                            })
                        }
                    </CheckboxWrapper>
                </Row>
                <Row>
                    <p>Pages</p>
                    <CheckboxWrapper>
                        {
                            options.page.map((item:{name:string,isSelected:boolean},index:number) => {
                                return (
                                    <Checkbox
                                    key={index}
                                    onChange={() => filterInputsHandler('page',index)}
                                    active={item.isSelected}
                                    labelValue={upperCaseFirstLetter(item.name)}
                                    />
                                )
                            })
                        }
                    </CheckboxWrapper>
                </Row>
            </DropdownWrapper>}
        </FilterWrapper>
    );
};

export default FilterByProps;