import styled from 'styled-components';

export const BodyWrapper = styled.div`
    margin:20px 0px 0px;
    display: flex;
    flex-direction: column;
    gap:10px;
`
export const LabelWrapper = styled.div`

`