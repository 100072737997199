import React from 'react';

const ShareIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3333 2C14.6261 2 13.9478 2.28095 13.4477 2.78105C12.9476 3.28115 12.6667 3.95942 12.6667 4.66667C12.6679 4.85411 12.6888 5.04091 12.7292 5.22396L6.67448 8.2513C6.42476 7.9638 6.11636 7.73312 5.77003 7.57478C5.4237 7.41644 5.04748 7.33411 4.66667 7.33333C3.95942 7.33333 3.28115 7.61428 2.78105 8.11438C2.28095 8.61448 2 9.29276 2 10C2 10.7072 2.28095 11.3855 2.78105 11.8856C3.28115 12.3857 3.95942 12.6667 4.66667 12.6667C5.0476 12.6662 5.42401 12.5842 5.77057 12.4261C6.11713 12.268 6.4258 12.0374 6.67578 11.75L12.7266 14.776C12.6871 14.9592 12.667 15.146 12.6667 15.3333C12.6667 16.0406 12.9476 16.7189 13.4477 17.219C13.9478 17.719 14.6261 18 15.3333 18C16.0406 18 16.7189 17.719 17.219 17.219C17.719 16.7189 18 16.0406 18 15.3333C18 14.6261 17.719 13.9478 17.219 13.4477C16.7189 12.9476 16.0406 12.6667 15.3333 12.6667C14.9524 12.6671 14.576 12.7491 14.2294 12.9073C13.8829 13.0654 13.5742 13.2959 13.3242 13.5833L7.27344 10.5573C7.31292 10.3741 7.333 10.1874 7.33333 10C7.33205 9.81299 7.3111 9.62664 7.27083 9.44401L13.3255 6.41667C13.5754 6.70393 13.8838 6.93437 14.2301 7.09248C14.5765 7.25059 14.9526 7.33272 15.3333 7.33333C16.0406 7.33333 16.7189 7.05238 17.219 6.55229C17.719 6.05219 18 5.37391 18 4.66667C18 3.95942 17.719 3.28115 17.219 2.78105C16.7189 2.28095 16.0406 2 15.3333 2Z" fill="#04A584"/>
        </svg>
    );
};

export default ShareIcon;