import Layout from '../components/layouts/main_layout/layout';
import Person from '../components/layouts/person_page_layout';

const PersonPage = () => {
    return (
        <Layout>
            <Person />
        </Layout>
    );
};

export default PersonPage;