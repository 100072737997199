import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    descriptionWrapper: {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '21px',
        marginBottom: 24,

        '& > span': {
            color: '#738094'
        },
    },
    tabContentWrapper: {
        marginTop: 16,
    }
})