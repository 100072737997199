import React, { useState } from 'react'
import { useQuery } from 'react-query'
import Checkbox from '../checkbox'
import InputWithLabel from '../components_for_modals/input_with_label'
import inputsHandler from '../../utils/inputsHandler'
import ModalDatePicker from '../components_for_modals/modal_date_picker'
import Button from '../button'
import { useStyles } from '../about_us/styles'
import FillButton from '../button/fill_button'
import reloadWindow from '../../utils/reloadWindow'
import updateBanner from '../../services/layout/updateBanner'
import fetchBanner from '../../services/layout/fetchBanner'

export interface IBannerSettings {
    text:string 
    date:Date 
    link:string 
    isVisible:boolean
}

const BannerSettings = () => {
    const 
    { 
        wrapper,
        title,
        row
    } = useStyles()
    const [banner,setBanner] = useState<IBannerSettings>({
        text:'',
        date:new Date(), 
        link:'',
        isVisible:false
    })
    const {data} = useQuery('banner',fetchBanner,{
        onSuccess:({data}) => {
            if(data?.banner) setBanner({
                ...data.banner,
                date:new Date(data.banner.date)
            })
        }
    })

    const confimSaveChanges = async () : Promise<void> => {
        await updateBanner(banner)

        reloadWindow()
    }

  return (
    <div className={wrapper}>
      <h2 className={title}>
        Banner
      </h2>
      <div className={row}>
        <Checkbox
        labelValue='Display'
        active={banner.isVisible}
        onChange={() => setBanner((prev:IBannerSettings) => {
            return {...prev,isVisible:!prev.isVisible}
        })}
        />
        <InputWithLabel
        label='Banner text'
        value={banner.text}
        onChange={(value:string) => inputsHandler('text',value,setBanner)}
        />
        <InputWithLabel
        label='Link'
        value={banner.link}
        onChange={(value:string) => inputsHandler('link',value,setBanner)}
        />
        <div>
            <p>Event Date</p>
            <ModalDatePicker
            name='date'
            date={banner.date}
            onChange={(value:any) => inputsHandler('date',value,setBanner)}
            />
        </div>
      </div>
      <FillButton
      onClick={confimSaveChanges}
      >
        Save updates
      </FillButton>
    </div>
  )
}

export default BannerSettings
