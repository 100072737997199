import {useState,useContext} from 'react';
import Tabs from '../../common/tabs';
import {NEWS_TABS_LIST, newsTabsList} from '../../../static_content/news_data';
import SmartTab from './news_tabs/smart_tab';
import ParcingTab from './news_tabs/parcing_tab';
import MarketTab from './news_tabs/market_tab';
import {useStyles} from './styles';
import EditIcon from '../../common/Icons/edit_icon';
import DescriptionModal from '../../common/global_modals/description_modal';
import useFetch from '../../hooks/useFetch';
import getAccessToken from '../../utils/getAccessToken';
import { INews } from '../../types/global_types';

const NewsLayout = ({type}:{type:string}) => {
    const [activeTab, setActiveTab] = useState(newsTabsList[0])
    const [isBioModal, setIsBioModal] = useState(false)
    const {data,loading} = useFetch(`news/all/${type}`,{headers:{'Authorization': `Bearer ${getAccessToken()}`}})

    const {
        descriptionWrapper,
        tabContentWrapper,
    } = useStyles()

    const handleTab = (value: string) => {
        setActiveTab(value as NEWS_TABS_LIST)
    }

    const handleChosenLayout = () => {
        switch(activeTab) {
            case NEWS_TABS_LIST.SMART:
                return <SmartTab />
            case NEWS_TABS_LIST.PARCING:
                return <ParcingTab />
            case NEWS_TABS_LIST.MARKET:
                return <MarketTab news={data?.data}/>
        }
    }
    
    return (
        <div className='container'>
            <div className={descriptionWrapper}>
   
            </div>
            <div>
                <Tabs
                    tabs={newsTabsList}
                    onChange={handleTab}
                    activeTab={activeTab}
                />
                <div className={tabContentWrapper}>
                    {handleChosenLayout()}
                </div>
            </div>
            {isBioModal && <DescriptionModal onClose={() => setIsBioModal(false)} />}
        </div>
    );
};

export default NewsLayout;