import React from 'react';

const CopyIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.86663 1.06667C5.2773 1.06667 4.79997 1.544 4.79997 2.13333V10.6667C4.79997 11.256 5.2773 11.7333 5.86663 11.7333H12.8C13.3893 11.7333 13.8666 11.256 13.8666 10.6667V4.53333C13.8666 4.392 13.8107 4.25652 13.7104 4.15625L10.7771 1.22292C10.6768 1.12265 10.5413 1.06667 10.4 1.06667H5.86663ZM10.1333 2.08229L12.851 4.8H10.6666C10.3722 4.8 10.1333 4.56107 10.1333 4.26667V2.08229ZM3.19997 3.73333C2.61063 3.73333 2.1333 4.21067 2.1333 4.8V13.3333C2.1333 13.9227 2.61063 14.4 3.19997 14.4H10.1333C10.7226 14.4 11.2 13.9227 11.2 13.3333V12.8H5.86663C4.6901 12.8 3.7333 11.8432 3.7333 10.6667V3.73333H3.19997Z" fill="#B9C0CA"/>
        </svg>
    );
};

export default CopyIcon;