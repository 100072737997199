import styled from 'styled-components';

export const Layout = styled.div`

`

export const CardsLayout = styled.div`
    padding: 12px 20px;
    & h2 {
        margin-bottom: 12px;
    }
`

export const ProgressLayout = styled.div`
    padding: 12px 20px;
    & h2 {
        margin-bottom: 12px;
    }   
`

export const SaveWrapper = styled.div`
    padding: 12px 20px;
`

export const InfoItemsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`