import styled from 'styled-components';


export const ProgressWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap:12px;
`

export const ProgressColumnWraper = styled.div`

`