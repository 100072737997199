import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  padding: 8px 16px;
  
  div {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #738094;
  }
`

export const ProjectWrapper = styled.div`
  width: 210px
`
export const StatusWrapper = styled.div`
  width: 130px
`
export const EventWrapper = styled.div`
  width: 175px
`
export const DateWrapper = styled.div`
  width: 202px
`
export const TimeWrapper = styled.div`
  width: 200px
`