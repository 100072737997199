export enum PROJECT_TABS_DATA {
    OVERVIEW = 'Overview',
    EXCHANGE = 'Exchange',
    FUNDRAISING = 'Fundraising',
    NEWS = 'News',
}

export enum ACTIVE_PROJECT_TABS_DATA {
    TOKEN_METRICS = 'Token Metrics',
    FUNDRAISING = 'Fundraising',
    NEWS = 'News',
    COMPARISON = 'Comparison',
}

export const ProjectTabsData = [
    PROJECT_TABS_DATA.OVERVIEW,
    PROJECT_TABS_DATA.EXCHANGE,
    PROJECT_TABS_DATA.FUNDRAISING,
    PROJECT_TABS_DATA.NEWS,
]

export const ActiveProjectTabsData = [
    ACTIVE_PROJECT_TABS_DATA.TOKEN_METRICS,
    ACTIVE_PROJECT_TABS_DATA.FUNDRAISING,
    ACTIVE_PROJECT_TABS_DATA.NEWS,
    ACTIVE_PROJECT_TABS_DATA.COMPARISON,
]