import React from 'react';

const FilterIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.66667 2.66666C2.57833 2.66541 2.49062 2.68173 2.40864 2.71468C2.32666 2.74762 2.25205 2.79653 2.18913 2.85856C2.12622 2.92059 2.07626 2.9945 2.04216 3.076C2.00806 3.15751 1.99051 3.24498 1.99051 3.33333C1.99051 3.42168 2.00806 3.50915 2.04216 3.59065C2.07626 3.67216 2.12622 3.74607 2.18913 3.8081C2.25205 3.87013 2.32666 3.91904 2.40864 3.95198C2.49062 3.98492 2.57833 4.00124 2.66667 4H3.20052L8 10H12L16.7995 4H17.3333C17.4217 4.00124 17.5094 3.98492 17.5914 3.95198C17.6733 3.91904 17.748 3.87013 17.8109 3.8081C17.8738 3.74607 17.9237 3.67216 17.9578 3.59065C17.9919 3.50915 18.0095 3.42168 18.0095 3.33333C18.0095 3.24498 17.9919 3.15751 17.9578 3.076C17.9237 2.9945 17.8738 2.92059 17.8109 2.85856C17.748 2.79653 17.6733 2.74762 17.5914 2.71468C17.5094 2.68173 17.4217 2.66541 17.3333 2.66666H2.66667ZM8 11.3333V17.3333L12 16V11.3333H8Z" fill="#00C099"/>
        </svg>
    );
};

export default FilterIcon;