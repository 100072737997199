// export const localApi = 'http://localhost:5000/api'
// export const localApi = 'https://fomoland-backend-production.up.railway.app/api'
export const localApi = 'https://api.fomo.wiki/api'

// export const loaderApi = 'http://localhost:5000'
// export const loaderApi = 'https://fomoland-backend-production.up.railway.app'
export const loaderApi = 'https://api.fomo.wiki'

export const configureUrl = (path:string) : string => {
    return `${localApi}/${path}`
}

export const configureFetchForm = (method:'POST' | 'PUT' | 'DELETE',body:object,headers:any) => {
    const data = new FormData();

    Object.entries(body).forEach((item) => {
        let isReturn = false
        const key:string = item[0]
        let value:any = item[1]
        
        if(key === 'fullness'){
            value = value + '%'
        }
        if(key === 'investors'){
            data.append(key,value?.map((item:any) => item?._id))
            isReturn = true
        }
        if(!isReturn){
            data.append(key,value)
        }
    })
    
    return {
        method,
        headers,
        body: data
    }
}

export const configureProjectForm = (method:'POST' | 'PUT' | 'DELETE',body:object,headers:any) => {
    const data = new FormData();

    Object.entries(body).forEach((item) => {
        let isReturn = false
        const key:string = item[0]
        let value:any = item[1]
        if(key === 'fullness'){
            value = value + '%'
        }
        if(key === 'investors'){
            data.append(key,value?.map((item:any) => item?._id))
            isReturn = true
        }

        if(key === 'team'){
            data.append(key,value?.map((item:any) => item?._id))
            isReturn = true
        }

        if(key === 'partners'){
            data.append(key,value?.map((item:any) => item?._id))
            isReturn = true
        }

        if(key === 'faq'){
            data.append(key,JSON.stringify(value))
            isReturn = true
        }

        if(key === 'recommendations'){
            data.append(key,value)
            isReturn = true
        }
        if(!isReturn){
            data.append(key,value)
        }
    })
    
    return {
        method,
        headers,
        body: data
    }
}