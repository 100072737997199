import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        margin: '0 23px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 'max-content',
    },
    mainWrapper: {
    },
    logoWrapper: {
        width: 149,
        height: 50,

        '& img': {
            width: '100%',
        },
    },
    linksWrapper: {
        display: 'flex',
        gap: 20,
        alignItems: 'center',
        color: '#070B35',
    },
    linkWrapper: {
        position: 'relative',
    },
    linkTitle: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        cursor: 'pointer',
        padding: '5px 0',

        '& svg': {
            transition: '.3s',
        },

        '& .active': {
            fontWeight: 600,
            '& svg': {
                '& path': {
                    fill: '#070B35'
                },
            },
        },
    },
    openDropdown: {
        '& svg': {
            transform: 'rotate(180deg)',
        },
    },
    linkDropdownWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        cursor:'pointer',
        '& img':{
            width:'32px',
            height:'auto',
            objectFit:'cover',
            borderRadius:'8px'
        }
    },
    dropdownWrapper: {
        position: 'absolute',
        top: '100%',
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        padding: 16,
        background: 'white',
        borderRadius: '8px',
        border: '1px solid rgba(83, 98, 124, 0.07)',
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.03)',
        zIndex: 2,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        width: 160,

        '& .active': {
            color: '#00C099',
            fontWeight: 600,
        },
    },
    logoutWrapper: {
        right: 0,
        left: 'auto',
    },
    userAvatar: {
        width: 32,
        height: 32,
        borerRadius: 100,
    },
    logoutButton: {
        width: 'max-content',
        border: 'none',
        background: 'none',
        color: '#FF5858',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
    },
    head:{
        display:'flex',
        alignItems:'center',
        gap:'12px',

        '& span':{
            transform:'translateY(3px)',
            textTransform:'uppercase',
            color:'rgb(0, 192, 153)',
            fontWeight:600,
            fontSize:'20px',
        }
    }
})