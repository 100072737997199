import React from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import Header from '../../components/layouts/gemslab_layouts/market_list_layout/header';
import ProjectTable from '../../components/layouts/gemslab_layouts/market_list_layout/project_table';

const MarketPage = () => {
    return (
        <Layout>
            <Header />
            <ProjectTable />
        </Layout>
    );
};

export default MarketPage;