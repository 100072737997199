import {useState,useCallback,useContext} from 'react';
import {useStyles} from './styles';
import TableRow from './table_row';
import Checkbox from '../../../common/checkbox';
import Loader from '../../../common/loader';
import { IAction } from '../../../types/global_types';
import { ConfirmingContext } from '../../../../pages/Confirming';
import ActionMessageModal from '../../../common/actionMessageModal';

const ConfirmingTable = () => {
    const {
        wrapper,
        headerWrapper,
        statusWrapper,
        userWrapper,
        checkboxWrapper,
        walletWrapper,
        pointsWrapper,
    } = useStyles()

    const {
        actions,
        loading,
        selectToggleAction,
        toggleAllActions
    } = useContext(ConfirmingContext)

    const [userId,setUserId] = useState<string>('')
    const [isMessageModal,setIsMessageModal] = useState<boolean>(false)
    const [isSelectAll,setIsSelectAll] = useState<boolean>(false)

    const selectAll = () => {
        setIsSelectAll((prev:boolean) => !prev)
        toggleAllActions && toggleAllActions(!isSelectAll)
    }
 
    const selectAction = useCallback((selectedAction:IAction) : void => {
        selectToggleAction && selectToggleAction(selectedAction._id)
    },[actions])

    const selectActionToMessage = (userId:string) => {
        setIsMessageModal(true)
        setUserId(userId)
    }
    console.log(userId)
    if(loading){
        return <Loader/>
    }
    
    return (
        <>
                <div className={wrapper}>
            <div className={`container ${headerWrapper}`}>
                <div className={checkboxWrapper}>
                    <Checkbox
                        onChange={selectAll}
                        active={isSelectAll}
                    />
                </div>
                <div className={userWrapper}>User</div>
                <div className={walletWrapper}>Project/News/Event</div>
                <div className={statusWrapper}>Action</div>
                <div className={pointsWrapper}>Date and time</div>
            </div>
            <div>
                {
                    actions.length
                    ?
                    actions.map((action:IAction,index) => {
                        return <TableRow 
                                key={action._id || index} 
                                action={action}
                                selectAction={selectAction}
                                selectActionToMessage={selectActionToMessage}
                                />
                    })
                    :
                    <></>
                }
            </div>
        </div>
        <ActionMessageModal
        userId={userId}
        isVisible={isMessageModal}
        onClose={() => {
            setIsMessageModal(false)
            setUserId('')
        }}
        />
        </>
    );
};

export default ConfirmingTable;