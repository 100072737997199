import styled from "styled-components";

export const Wrapper = styled.div`
    width:95%;
    margin:40px auto;
`

export const SectionWrapper = styled.div`
margin: 20px 0;
`

export const SectionTitle = styled.p`
font-weight: 500;
`

export const SectionItems = styled.div`
    margin-top: 10px;
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    gap:15px;
`