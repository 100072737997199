import styled from 'styled-components';

export const RangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const RangeValues = styled.div`
  color: #070B35;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
`