import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    editMainWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        color: '#2082EA',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            }
        }
    },
    headerWrapper: {
        display: 'flex',
        padding: '8px 16px',

        '& > div': {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '14px',
            color: '#738094',
        },
    },
    exchangeWrapper: {
        width: 210,
    },
    pairWrapper: {
        width: 180
    },
    priceWrapper: {
        width: 175,
    },
    volumeWrapper: {
        width: 155,
    },
    percentVolumeWrapper: {
        width: 130,
    },
    updatedWrapper: {
        width: 200,
    },
})