import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        display:'flex',
        gap:50,
        width: 1366,
        margin: '24px auto 0',
    },
    inputWrapper: {
        width: 328,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 12,
    },
    input: {
        padding: '10px 12px',
        width: 251,
        borderRadius: 8,
        background: '#F8F8F9',
        border: 'none',

        '&::placeholder': {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            color: 'rgba(115, 128, 148, 0.5)',
        }
    },
    label: {
        color: '#738094',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
    },
    button: {
        width: 328,
        marginTop: 12,
    },
    column: {

    },
    subTitle: {
        fontWeight:600,
        marginBottom:14,
    },
    legalItem: {
    },
    legalItems: {
        display:'flex',
        flexWrap:'wrap',
        gap:15,
    }
})