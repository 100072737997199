import { FC } from 'react';
import { ITask } from '../../../types/global_types';
import {HeaderWrapper, ProjectWrapper, TimeWrapper } from './styles';
import TableRow from './table_row';

interface IProps {
    events: Array<ITask>
}

const CalendarTableLayout : FC<IProps> = ({events}) => {
    return (
        <div>
            <HeaderWrapper>
                <ProjectWrapper>Task</ProjectWrapper>
                <ProjectWrapper>Link</ProjectWrapper>
                <TimeWrapper>Time left</TimeWrapper>
                <TimeWrapper>Points</TimeWrapper>
            </HeaderWrapper>
            <div>
                {
                    events.map((eventItem) => {
                        return <TableRow key={eventItem._id} event={eventItem}/>
                    })
                }
            </div>
        </div>
    );
};

export default CalendarTableLayout;