import { useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import deleteFag from '../../../services/fag/deleteFag';
import Button from '../../../common/button';
import Loader from '../../../common/loader';
import CreateFAQModal from './modals/create/CreateFAQModal';
import UpdateFAQModal from './modals/update/UpdateFAQModal';
import {useStyles} from './styles';

export interface FAQItem {
    _id?:string
    title:string
    description:string
    items:Array<{title:string,description:string}>
}

const CreateFAQLayout = () => {
    const {
        wrapper,
        textField,
        body,
        head,
        itemWrapper,
        textFieldWrapper,
        buttons,
        declineButton
    } 
    = useStyles()
    const [faqToUpdate,setFaqToUpdate] = useState<FAQItem>({title:'',description:'',items:[]})
    const [loading,setLoading] = useState<boolean>(false)
    const [isUpdateModal,setIsUpdateModal] = useState<boolean>(false)
    const [isCreateModal,setIsCreateModal] = useState<boolean>(false)
    const [faqItems,setFaqItems] = useState<Array<FAQItem>>([])
    const {data} = useFetch('faq')

    const confirmDeleteFaq = async (id:string) : Promise<void> => {
        setLoading(true)

        const {success} = await deleteFag(id)

        if(success){
            setFaqItems((prev:Array<FAQItem>) => {
                return (
                    prev.filter((item:FAQItem) => item._id !== id)
                )
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        data?.data && setFaqItems(data?.data)
    },[data])

    if(loading) return <Loader/>

  return (
    <>
    <div className={wrapper}>
        <div className={head}>
            <Button
            onClick={() => setIsCreateModal(true)}
            type={'bordered'}
            >
                Add FAQ 
            </Button>
        </div>
        <div className={body}>
        {
            faqItems.map((faqItem:FAQItem,index:number) => {
                return (
                    <div 
                    className={itemWrapper}
                    key={index}
                    >
                        <div className={textFieldWrapper}>
                            <label>
                            Section title:
                            </label>
                            <div>
                                {faqItem.title}
                            </div>
                        </div>
                        <div className={textFieldWrapper}>
                            <label>
                            Section description:
                            </label>
                            <div>
                                {faqItem.description}
                            </div>
                        </div>
                        <div className={buttons}>
                            <Button
                            onClick={() => {
                                setFaqToUpdate(faqItem)
                                setIsUpdateModal(true)
                            }}
                            type={'lightFill'}
                            >
                                Update
                            </Button>
                            <Button
                            className={declineButton}
                            onClick={() => confirmDeleteFaq(String(faqItem._id))}
                            type={'bordered'}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                )
            })
        }
        </div>
    </div>
    <CreateFAQModal
    onCreatedNewFAQ={(faq:FAQItem) => setFaqItems((prev) => [faq,...prev])}
    isVisible={isCreateModal}
    onClose={() => setIsCreateModal(false)}
    />
    <UpdateFAQModal
    onUpdateFAQ={(faqToUpdate:FAQItem) => setFaqItems((prev) => {
        return (
            prev.map((item: FAQItem) => {
                if(item._id === faqToUpdate._id){
                    return faqToUpdate
                }

                return item
            })
        )
    })}
    isVisible={isUpdateModal}
    onClose={() => setIsUpdateModal(false)}
    faqInitial={faqToUpdate}
    />
    </>
  )
}

export default CreateFAQLayout
