import Layout from '../../components/layouts/main_layout/layout';
import TaskItemPage from '../../components/layouts/tasks_layout/task_page';

const TaskPage = () => {
  return (
    <Layout>
      <TaskItemPage/>
    </Layout>
  )
}

export default TaskPage
