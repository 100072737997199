import {createContext, useEffect} from 'react';
import { useConnectWallet } from '../../components/hooks/useConnectWallet';
import Layout from '../../components/layouts/main_layout/layout';
import Header from '../../components/layouts/gemslab_layouts/projects_layout/header';
import ProjectTable from '../../components/layouts/gemslab_layouts/projects_layout/project_table';
import useFetch from '../../components/hooks/useFetch';
import getUserRole from '../../components/utils/getUserRole';
import getAccessToken from '../../components/utils/getAccessToken';
import Loader from '../../components/common/loader';
import { IProject } from '../../components/hooks/useCreateProject';

interface IProjectContext {
    projects:Array<IProject>
}

export const ProjectContext = createContext<IProjectContext>({projects:[]})

const ProjectsPage = () => {
    const {connectWallet} = useConnectWallet()
    const {loading,data} = useFetch(
        `projects/${getUserRole()}/gemslab`,
        {headers:{'Authorization': `Bearer ${getAccessToken()}`}},
    )

    useEffect(() => {
        connectWallet()
    },[])
    
    if(loading){
        return <Loader/>
    }   

    return (
        <ProjectContext.Provider
        value={{projects:data?.data || []}}
        >
            <Layout>
                <Header />
                <ProjectTable />
            </Layout>
        </ProjectContext.Provider>
    );
};

export default ProjectsPage;