import React from 'react';

const CheckIcon = () => {
    return (
        <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5.5L6 10L16 1" stroke="#04A584" strokeWidth="2"/>
        </svg>
    );
};

export default CheckIcon;