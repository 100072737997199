import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    preHeaderWrapper: {
        marginTop: 16,
    },
    preHeaderRightWrapper: {
        display: 'flex',
        marginBottom: 20,
        gap: 20,
        justifyContent: 'flex-end',
    },
    editMainWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        color: '#2082EA',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            }
        }
    },
    shareWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        color: '#04A584',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#04A584'
            }
        }
    },
    headerWrapper: {
        display: 'flex',
        gap: 14,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    leftHeaderWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    projectWrapper: {
        display: 'flex',
        gap: 16,
        alignItems: 'center',
    },
    projectImage: {
        width: 64,
        borderRadius: 100,
    },
    projectTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        marginBottom: 4,

        '& h1': {
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '39px',
        }
    },
    projectDescriptionWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    projectDescription: {
        color: '#738094',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '21px',
    },
    socialNetworksWrapper: {
        marginLeft: 8,
        display: 'flex',
        gap: 8,
    },
    projectDataWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },
    projectDataCellTitle: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '29px',
    },
    projectDataCellDescription: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: 6,

        '& svg': {
            width: 16,
            height: 16,
            '&  path': {
                fill: '#2082EA',
            }
        }
    },
    projectDataActionsWrapper: {
        display: 'flex',
        marginLeft: 8,
    },
    bioWrapper: {
        marginBottom: 10,
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '21px',

        '& span': {
            color: '#738094',
        },

        '& svg': {
            width: 16,
            cursor: 'pointer',

            '& path': {
                fill: '#2082EA',
            }
        },
    },
    followersWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 27,
    },
    guideWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 9,
        fontWeight: 400,
        lineHeight: '16px',
        fontSize: '14px',
        color: '#738094',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA',
            }
        }
    },
    progressTitle: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        marginTop: 19,

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA',
            }
        }
    },
    progressValue: {
        background: 'linear-gradient(270deg, #04A584 0%, #00C099 100%)',
        borderRadius: 8,
        height: 8,
        width: '100%',
        marginTop: 8,
        marginBottom: 13,
    },
    progressValueDescription: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        gap: 6,

        '& i': {
            color: '#738094',
        },

        '& span': {
            color: '#00C099',
        }
    },
    projectDescWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: 33,

        '&>div': {
            width: '48%',
        }
    },
    contractWrapper: {
        cursor: 'pointer',
        padding: '6px 8px',
        display: 'flex',
        alignItems: 'center',
        background: '#F3F4F6',
        borderRadius: 8,
        gap: 4,

        '& svg path': {
            fill: '#738094',
        }
    },
    descriptionWrapper: {
        display: 'flex',
        width: '100%',
        gap: 90,
        marginTop: 18,
        marginBottom: 20,
        borderTop: '2px solid #F8F8F9',
        borderBottom: '2px solid #F8F8F9',
        padding: '17px 0',
    },
    descriptionItemTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        marginBottom: 5,
        color: '#738094',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        }
    },
    descriptionItemValue: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,

        '& span': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',

            '&.green': {
                color: '#00C099'
            },

            '&.gray': {
                color: '#738094',
            }
        },
    },
    subsWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        marginTop: 7,
    },
    subsItem: {
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',

        '& img': {
            width: 20,
            height: 20,
            borderRadius: 100,
        },
    },
    graphicWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    graphicTopWrapper: {
        width: 320,
        background: 'white',
        padding: 16,
        borderRadius: 8,
        border: '1px solid rgba(83, 98, 124, 0.07)',
        boxShadow: '4px 4px 0px #EEEEEE',
        marginBottom: 16,
    },
    graphicTopTitle: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        marginBottom: 16,

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        }
    },
    graphicTopRow: {
        display: 'flex',
        gap: 54,
    },
    graphicTopCell: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,

        '& > div': {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
        },

        '& span': {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '19px',
        },
    },
    graphicBottomRow: {
        display: 'flex',
        justifyContent: 'space-between',

        '&:not(:first-child)': {
            paddingTop: 19,
        },
        '&:not(:last-child)': {
            borderBottom: '2px solid #F8F8F9',
            paddingBottom: 10,
        }
    },
    graphicBottomRowTitle: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        color: '#738094',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        },

        '& + span': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#738094',
        }
    },
    graphicBottomRowValue: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        textAlign: 'right',

        '& span': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
        }
    },
    contentWrapper: {
        marginTop: 52,
    },
    contentTitle: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#738094',
        marginBottom: 14,
    },
    nftsHeaderWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    flexCardWrapper: {
        marginTop: 15,
        display: 'flex',
        gap: 17,
        flexWrap: 'wrap',
    },
    investorsEditRow: {
        display: 'flex',
        alignItems: 'center',
        color: '#2082EA',
        marginTop: 8,
        gap: 6,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        }
    },
    flagsColumnTitle: {
        color: '#738094',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        gap: 6,
        display: 'flex',
        alignItems: 'center',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        }
    },
    flagsContentWrapper: {
        display: 'flex',
        gap: 54,
        width: '100%',

        '& > div': {
            width: '40%',
        },
    },
    flagsColumnWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 13,
        marginTop: 12,

        '& > div': {
            display: 'flex',
            alignItems: 'center',
            gap: 6,
        },
    },
    ratingsMediaLinksWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,

        '& a': {
            display: 'flex',
            alignItems: 'center',
            gap: 6,
            color: '#04A584',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
        },
    }
})