import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        border: '1px solid #04A584',
        background: 'white',
        borderRadius: 8,
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '19px',
        color: '#04A584',
        padding: '8px 12px',
        transition:'all 0.3s ease',
        '&:hover': {
            background:'#04A584',
            color:'white'
        }
    },
})