import React, {useState} from 'react';
import EditIcon from '../../../../common/Icons/edit_icon';
import {useStyles} from './styles';
import { ITwitterAcc } from '../../../../types/global_types';
import SearchIcon from '../../../../common/Icons/search_icon';
import Filter from '../../../../common/filter';
import SortBy from '../../../../common/sort_by';
import ParcingItem from './parcing_item';
import Button from '../../../../common/button';
import DescriptionModal from '../../../../common/global_modals/description_modal';
import AddTwitterUserModal from '../../modals/add_twitter_user_modal';
import UpdateTwitterUserModal from '../../modals/update_twitter_user_modal';
import useFetch from '../../../../hooks/useFetch';
import Loader from '../../../../common/loader';

const ParcingTab = () => {
    const {
        tabsWrapper,
        tab,
        activeTab,
        descriptionWrapper,
        searchWrapper,
        searchInput,
        actionsWrapper,
        accWrapper,
        filterWrapper,
    } = useStyles()
    const [active, setActive] = useState(false)
    const [isBioModal, setIsBioModal] = useState(false)
    const [isAddTwitterModal, seIsAddTwitterModal] = useState(false)
    const [isUpdateTwitterModal, seIsUpdateTwitterModal] = useState(false)
    const {data,loading} = useFetch('socialparcing')
   
    if(loading || !data) return <Loader/>

    const parcingData : Array<ITwitterAcc> = data?.data

    return (
        <div>
            <div className={tabsWrapper}>
                {/* <div
                    className={active ? activeTab : tab}
                    onClick={() => setActive(true)}
                >
                    Main
                </div>
                <div
                    className={!active ? activeTab : tab}
                    onClick={() => setActive(false)}
                >
                    Favourites
                </div> */}
            </div>
            <div className={descriptionWrapper}>
        
            </div>
            <div className={searchWrapper}>
                <SearchIcon />
                <input
                    type="text"
                    placeholder='Search'
                    className={searchInput}
                />
            </div>
            <div className={actionsWrapper}>
                <div className={filterWrapper}>
                    <Filter />
                    <Button
                        onClick={() => seIsAddTwitterModal(true)}
                        type='bordered'
                    >
                        Add twitter acc
                    </Button>
                </div>
                <SortBy />
            </div>
            <div className={accWrapper}>
                {
                    parcingData.map((item : ITwitterAcc) => {
                        return (
                            <ParcingItem 
                            key={item._id}
                            item={item}
                            openModal={() => seIsUpdateTwitterModal(true)} />
                        )
                    })
                }
                
            </div>
            {isBioModal && <DescriptionModal onClose={() => setIsBioModal(false)} />}
            {isAddTwitterModal && <AddTwitterUserModal onClose={() => seIsAddTwitterModal(false)} />}
            {isUpdateTwitterModal && <UpdateTwitterUserModal onClose={() => seIsUpdateTwitterModal(false)} />}
        </div>
    );
};

export default ParcingTab;