import React from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import AcceleratorProjectLayout from '../../components/layouts/gemslab_layouts/accelerator_project_layout';

const AcceleratorProjectPage = () => {
    return (
        <Layout>
            <AcceleratorProjectLayout />
        </Layout>
    );
};

export default AcceleratorProjectPage;