import React from 'react';

const TwitterIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6667 4.62467C18.0287 4.908 17.3433 5.09867 16.624 5.18467C17.3587 4.74467 17.922 4.048 18.1873 3.21734C17.5007 3.62467 16.7393 3.92067 15.9287 4.08067C15.28 3.38934 14.356 2.95734 13.3333 2.95734C11.3693 2.95734 9.77734 4.55 9.77734 6.51334C9.77734 6.792 9.80934 7.064 9.86934 7.32334C6.91401 7.17534 4.29401 5.75934 2.53934 3.60734C2.23401 4.13267 2.05867 4.74334 2.05867 5.396C2.05867 6.62934 2.68601 7.718 3.64001 8.35534C3.05734 8.33667 2.50867 8.17667 2.02934 7.91067C2.02934 7.926 2.02934 7.94 2.02934 7.95534C2.02934 9.67867 3.25467 11.116 4.88201 11.442C4.58401 11.5233 4.26934 11.5667 3.94467 11.5667C3.71601 11.5667 3.49267 11.544 3.27601 11.5033C3.72867 12.916 5.04201 13.9447 6.59801 13.9733C5.38134 14.9273 3.84801 15.496 2.18134 15.496C1.89467 15.496 1.61134 15.4793 1.33267 15.446C2.90667 16.4547 4.77534 17.0433 6.78401 17.0433C13.3253 17.0433 16.9013 11.6247 16.9013 6.92534C16.9013 6.77134 16.898 6.618 16.8913 6.46534C17.5867 5.96334 18.19 5.33734 18.6667 4.62467Z" fill="#00C099"/>
        </svg>
    );
};

export default TwitterIcon;