import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    descriptionWrapper: {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '21px',
        marginBottom: 12,

        '& > span': {
            color: '#738094'
        },
    },
    newsWrapper: {
        marginTop: 19,
        display: 'flex',
        gap: 16,
        flexWrap: 'wrap',
        justifyContent:'center'
    },
})