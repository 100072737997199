import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 100,
    },
    logoWrapper: {
        width: 149,
        height: 50,

        '& img': {
            width: '100%',
        },
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    errorNumber: {
        fontWeight: 700,
        fontSize: 320,
        color: '#070B35',
        lineHeight: '387px',
        marginBottom: -50,
    },
    title: {
        fontWeight: 700,
        fontSize: 40,
        lineHeight: '48px',
        color: '#070B35',
    },
    link: {
        marginTop: 24,
        background: '#04A584',
        borderRadius: 8,
        border: 'none',
        padding: 13,
        width: 328,
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '22px',
        color: 'white',
        textAlign: 'center',
    },
})