import Layout from '../../components/layouts/main_layout/layout'
import BannerLayout from '../../components/layouts/gemslab_layouts/banner_layouts'

const Banner = () => {
  return (
    <Layout>
        <BannerLayout/>
    </Layout>
  )
}

export default Banner
