import React, {FC} from 'react';
import { IconInterface } from './IconInterface';

const FilterIcon: FC<IconInterface> = ({className, fill = 'black'}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M2.66667 2.66667C2.57833 2.66542 2.49062 2.68174 2.40864 2.71468C2.32666 2.74762 2.25205 2.79653 2.18914 2.85856C2.12622 2.92059 2.07627 2.9945 2.04217 3.07601C2.00807 3.15751 1.99051 3.24498 1.99051 3.33333C1.99051 3.42168 2.00807 3.50915 2.04217 3.59066C2.07627 3.67216 2.12622 3.74608 2.18914 3.80811C2.25205 3.87014 2.32666 3.91904 2.40864 3.95199C2.49062 3.98493 2.57833 4.00125 2.66667 4H3.20052L8 10H12L16.7995 4H17.3333C17.4217 4.00125 17.5094 3.98493 17.5914 3.95199C17.6733 3.91904 17.748 3.87014 17.8109 3.80811C17.8738 3.74608 17.9237 3.67216 17.9578 3.59066C17.9919 3.50915 18.0095 3.42168 18.0095 3.33333C18.0095 3.24498 17.9919 3.15751 17.9578 3.07601C17.9237 2.9945 17.8738 2.92059 17.8109 2.85856C17.748 2.79653 17.6733 2.74762 17.5914 2.71468C17.5094 2.68174 17.4217 2.66542 17.3333 2.66667H2.66667ZM8 11.3333V17.3333L12 16V11.3333H8Z" fill={fill}/>
        </svg>
    );
};

export default FilterIcon;