import React from 'react';

const FacebookIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2C5.582 2 2 5.582 2 10C2 14.0107 4.95467 17.3227 8.804 17.9013V12.12H6.82467V10.0173H8.804V8.618C8.804 6.30133 9.93267 5.28467 11.858 5.28467C12.78 5.28467 13.268 5.35333 13.4987 5.384V7.21933H12.1853C11.368 7.21933 11.0827 7.99467 11.0827 8.868V10.0173H13.478L13.1533 12.12H11.0827V17.918C14.9873 17.3887 18 14.05 18 10C18 5.582 14.418 2 10 2Z" fill="#00C099"/>
        </svg>
    );
};

export default FacebookIcon;