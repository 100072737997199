import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    editWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#2082EA',
        marginBottom: 8,

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        }
    },
    newsRowWrapper: {
        display: 'flex',
        width: '100%',
        overflowX: 'auto',
        gap: 16,
        paddingBottom: 20,
        marginBottom: 24,
    },
    newsWrapper: {
        minWidth: 360,
        border: '1px solid rgba(83, 98, 124, 0.07)',
        boxShadow: '4px 4px 0px #EEEEEE',
        borderRadius: 8,
        padding: 16,
    },
    newsTitle: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        marginBottom: 10,
    },
    contentRow: {
        display: 'flex',
        marginBottom: 12,

        '& > div': {
            width: '50%',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#738094',

            '& > span': {
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '17px',
            }
        }
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 32,
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#738094',
        marginBottom: 16,

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        }
    },
    chartWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },
    colorCircle: {
        width: 12,
        height: 12,
        borderRadius: 100,
    },
    totalsRow: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#738094',
        display: 'flex',
        gap: 12,
        marginBottom: 13,

        '& > span': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    dataRow: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#738094',

        '& > span': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
        },

        '&:first-child': {
            marginTop: 24,
        },
        '&:not(:last-child)': {
            marginBottom: 13,
        }
    },
    metricsWrapper: {
        display: 'flex',
        gap: 72,
    },
    metricRow: {
        display: 'flex',
        gap: 22,
        marginBottom: 13,

        '& > div:first-child': {
            width: 140,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#738094',
            display: 'flex',
            gap: 6,
            alignItems: 'center',

            '& svg': {
                width: 16,

                '& path': {
                    fill: '#2082EA'
                },
            }
        },
        '& > div:last-child': {
            width: '58%',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
        }
    },
    longMetricRow: {
        display: 'flex',
        gap: 22,
        marginBottom: 13,

        '& > div:first-child': {
            width: 180,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#738094',
            display: 'flex',
            gap: 6,
            alignItems: 'center',

            '& svg': {
                width: 16,

                '& path': {
                    fill: '#2082EA'
                },
            }
        },
        '& > div:last-child': {
            width: 80,
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
        }
    },
})