import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        margin:'20px 35px 10px 35px'
    },
    textField: {
        background: '#F8F8F9',
        borderRadius: '8px',
        padding: '9px 12px',
        width: '100%',
        height: '105px',
        resize: 'none',
        border: 'none',
        marginTop: '7px',
    },
    body:{
        marginTop:'15px',
        display:'flex',
        flexDirection:'column',
        gap:'10px'
    },
    itemWrapper:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        gap:'3px',
        padding:'18px 8px',
        border: '1px solid #04A584',
        borderRadius:'8px'
    },
    head:{

    },
    textFieldWrapper:{
        marginTop:"10px",
        display:'flex',
        flexDirection:'column',
        '& label':{
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '17px',
            color: 'rgb(115, 128, 148)'
        },
        '& div':{
            marginTop:'10px',
            fontSize: '17px',
            lineHeight: '17px',
        }
    },
    buttons:{
        marginTop:"14px",
        display:'flex',
        gap:'10px'
    },
    declineButton: {
        borderColor: '#FF5858',
        color: '#FF5858',

        '&:hover':{
            borderColor: '#FF5858 !important',
            background: '#FF5858 !important',
        }
    }
})