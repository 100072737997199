import styled from 'styled-components';

export const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #738094;
  margin-bottom: 7px;
`

export const Input = styled.input`
  background: #F8F8F9;
  border-radius: 8px;
  border: none;
  padding: 10px 12px;
  width: 100%;
  font-weight: 500;

  &::placeholder{
    font-weight: 400;
  }

`