import Layout from '../../components/layouts/main_layout/layout';
import Header from '../../components/layouts/users_list_layout/users_list/header';
import UsersTable from '../../components/layouts/users_list_layout/users_list/users_table';

const UsersListPage = () => {
    return (
        <Layout>
            <Header />
            <UsersTable />
        </Layout>
    );
};

export default UsersListPage;