import { FC } from "react"
import Button from ".."

interface IProps {
    onClick:any
}

const EditButton : FC<IProps> = ({onClick}) => {
  return (
    <Button
    type={'default'}
    onClick={onClick}
    >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.175 1.6C11.902 1.6 11.6291 1.70423 11.4208 1.9125L10.1333 3.2L12.8 5.86667L14.0875 4.57917C14.504 4.16263 14.504 3.48737 14.0875 3.07083L12.9291 1.9125C12.7209 1.70423 12.4479 1.6 12.175 1.6ZM9.06664 4.26667L2.80518 10.5281C2.80518 10.5281 3.29466 10.4843 3.47706 10.6667C3.65946 10.8491 3.50931 12.0427 3.73331 12.2667C3.95731 12.4907 5.14338 12.333 5.31352 12.5031C5.48365 12.6733 5.47185 13.1948 5.47185 13.1948L11.7333 6.93333L9.06664 4.26667ZM2.13331 12.2667L1.63018 13.6917C1.61041 13.7479 1.60019 13.8071 1.59998 13.8667C1.59998 14.0081 1.65617 14.1438 1.75619 14.2438C1.8562 14.3438 1.99186 14.4 2.13331 14.4C2.19292 14.3998 2.25207 14.3896 2.30831 14.3698C2.31005 14.3691 2.31178 14.3684 2.31352 14.3677L2.32706 14.3635C2.3281 14.3629 2.32914 14.3622 2.33018 14.3615L3.73331 13.8667L2.93331 13.0667L2.13331 12.2667Z" fill="#00C099"></path></svg>
    </Button>
  )
}

export default EditButton
