import {FC} from 'react';
import TextEditor from '../text_editor/TextEditor';
import Modal from '../modal';
import { useStyles } from "./styles"
import FillButton from '../button/fill_button';

interface IProps { 
    title:string
    value: string,
    onChange: (value: string ,name: string) => void
    onClose: () => void
    onClick: () => void
}

const TextModal : FC<IProps> = ({title,value,onChange,onClose,onClick}) => {
    const {button} = useStyles()


  return (
    <Modal
    title={title}
    variant='big'
    onClose={onClose}
    >
    <div>
        <TextEditor
        value={value}
        onChange={onChange}
        />
        <div className={button}>
        <FillButton
        onClick={onClick}
        >
            Confrim
        </FillButton>
        </div>
    </div>
    </Modal>
  )
}

export default TextModal
