import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
    }
})