import Layout from "../../../components/layouts/main_layout/layout"
import ModeratorLayout from "../../../components/layouts/moderator_layout/moderator"

export default function ModeratorPage() {

  return (
    <Layout>
      <ModeratorLayout/>
    </Layout>
  )
}
