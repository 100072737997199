import React from 'react';
import {Switch, useRouteMatch} from 'react-router';
import {Route} from 'react-router-dom';
import Recommendation from './Recommendation';
import Calendar from './Calendar';
import News from './News';
import Promohub from './Promohub';
import NotFoundPage from '../NotFound';
import Project from './Project';
import NewsPage from './News/NewsPage';
import TasksPage from './Tasks';
import TaskPage from './TaskPage';

const EarlylandPages = () => {
    const {path} = useRouteMatch()

    return (
        <>
            <Switch>
                <Route exact path={path} component={Recommendation} />
                <Route exact path={`${path}/project/:id`} component={Project} />
                <Route path={`${path}/calendar`} component={Calendar} />
                <Route path={`${path}/news/:id`} component={NewsPage} />
                <Route path={`${path}/news/`} component={News} />
                <Route path={`${path}/promo_hub`} component={Promohub} />
                <Route path={`${path}/tasks/:id`} component={TaskPage} />
                <Route path={`${path}/tasks`} component={TasksPage} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </>
    );
};

export default EarlylandPages;