import styled from "styled-components"

export const CreatingCollection = styled.div`
margin-top: 16px;
`

export const InputsRow = styled.div`
    margin-top: 12px;
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;

    div{
        width: 100%;
    }
`

export const DatesInputsRow = styled.div`
    margin-top: 12px;
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;

    & > div{
        width: 100%;
    }
    
`

export const ButtonWrapper = styled.div`
    margin-top: 16px;
    width: 100%;

    button {
        width: 100%;
    }
`

export const DateWrapper = styled.div`
`

export const DateWrapperLabel = styled.div`

`