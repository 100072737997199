import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    value: {
        color: 'rgba(115, 128, 148, 0.5)',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
    },
    activeValue: {
        color: '#738094',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px',
    },
})