import styled from 'styled-components';
import Button from '../../button';

export const ModalRow = styled.div`
  margin-top: 20px;
  
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #738094;
  }
  
  textarea {
    background: #F8F8F9;
    border-radius: 8px;
    padding: 9px 12px;
    width: 100%;
    height: 105px;
    resize: none;
    border: none;
    margin-top: 7px;
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`

export const RemoveButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
  background:#DC143C !important;
`
