import {useEffect,FC,useState} from 'react';
import Modal from '../../../../common/modal';
import InputWithLabel from '../../../../common/components_for_modals/input_with_label';
import {ModalRow} from '../../../news_layout/modals/create_news_modal/styles';
import {SubmitButton} from '../../../users_list_layout/modals/update_user_modal/styles';
import ModalDatePicker from '../../../../common/components_for_modals/modal_date_picker';
import { ITask ,INft} from '../../../../types/global_types';
import useUpdateTask from '../../../../hooks/useUpdateTask';
import useFetch from '../../../../hooks/useFetch';
import getUserId from '../../../../utils/getUserId';
import { configureFetchForm } from '../../../../services/config';
import getAccessToken from '../../../../utils/getAccessToken';
import reloadWindow from '../../../../utils/reloadWindow';
import getProjects from '../../../../services/projects/getProjects';
import Loader from '../../../../common/loader';
import { IProject } from '../../../../hooks/useCreateProject';
import { useSelector } from 'react-redux';
import ModalSelectProject from '../../../../common/components_for_modals/modal_select-project';
import useProjectPath from '../../../../hooks/useProjectPath';
import TextEditor from '../../../../common/text_editor/TextEditor';

interface Props {
    onClose: () => void;
    task:ITask
}

const UpdateEventModal: FC<Props> = ({onClose,task}) => {
    const {data,inputsHandler} = useUpdateTask(task)
    const [allProjects,setAllProjects] = useState<Array<IProject | INft>
    >([])
    
    const [selectedProject,setSelectedProject] = useState<IProject | INft>(data.project || allProjects[0])
    const userRole = useSelector((state:any) => state.auth.role)
    const location = useProjectPath()

    const dateHandler = (value:any,name:string) => {
        inputsHandler(value,name)
    }
        
    const {loading,dataHandler} = useFetch(
        `tasks/${task._id}`,
        configureFetchForm('PUT',data,{'Authorization': `Bearer ${getAccessToken()}`}),
        true
    )
    
    const projectHandler = (project:IProject | INft) => {
        setSelectedProject(project)
        inputsHandler(project._id || '','projectId')
    }

    const confirmUpdateTask = async () => {
        await dataHandler()
        onClose()   
        reloadWindow()
    }

    useEffect(() => {
        const fetchProjects = async () => {
            const {success,data} = await getProjects(location || 'project')
            if(success){
                inputsHandler(data[0]._id,'projectId')
                setAllProjects(data)
            }
        }
        fetchProjects()
    },[])

    if(loading) return <Loader/>

    return (
        <Modal
            title='Update task'
            onClose={onClose}
            variant='medium'
        >
    <ModalRow>
                <InputWithLabel
                    placeholder='New task title'
                    label='Title'
                    name='name'
                    value={data.name}
                    onChange={inputsHandler}
                />
            </ModalRow>
            <ModalRow>
                <InputWithLabel
                    placeholder='https://test.com'
                    label='Link'
                    name='link'
                    value={data.link}
                    onChange={inputsHandler}
                />
            </ModalRow>
            <ModalRow>
                <p>
                    Description
                </p>
                <TextEditor
                value={data.description}
                onChange={inputsHandler}
                name={'description'}
                />
            </ModalRow>
            <ModalRow>
                <p>Date</p>                             
                <ModalDatePicker
                name='date'
                date={new Date(data.date)} 
                onChange={dateHandler} />
            </ModalRow>
            <ModalRow>
                <InputWithLabel
                    placeholder="24:00"
                    label='Time'    
                    name='time'
                    value={data.time}
                    onChange={inputsHandler}
                />
            </ModalRow>
            <ModalRow>
                <InputWithLabel
                    placeholder='50'
                    label='Points'
                    name='points'
                    value={String(data.points)}
                    onChange={inputsHandler}
                    type={'number'}
                />
            </ModalRow>
            <ModalRow>
                <ModalSelectProject
                    items={allProjects}
                    label='Choose project'
                    project={selectedProject || allProjects[0]}
                    onChange={projectHandler}
                />
            </ModalRow>
            <SubmitButton onClick={confirmUpdateTask} type='fill'>
                Save changes
            </SubmitButton>
        </Modal>
    );
};

export default UpdateEventModal;