import { useEffect, useState } from 'react';
import { ISocial, ILegal, ISocialItem} from '../../../types/global_types';
import useFetch from '../../../hooks/useFetch';
import Button from '../../../common/button';
import inputsHandler from '../../../utils/inputsHandler';
import TextModal from '../../../common/text_modal/TextModal';
import updateFooter from '../../../services/layout/updateFooter';
import reloadWindow from '../../../utils/reloadWindow';
import Loader from '../../../common/loader';
import {useStyles} from './styles';

const FooterLayout = () => {
    const {
        wrapper,
        inputWrapper,
        input,
        label,
        button,
        column,
        subTitle,
        legalItem,
        legalItems
    } = useStyles()
    const {data,loading} = useFetch('layout')
    const [isPolicy,setIsPolicy] = useState<boolean>(false)
    const [isTerms,setIsTerms] = useState<boolean>(false)
    const [isDisclaimer,setIsDisclaimer] = useState<boolean>(false)
    const [isCareers,setIsCareers] = useState<boolean>(false)
    
    const [legal,setLegal] = useState<ILegal>({policy:'',terms:'',disclaimer:'',careers:''})
    const [social,setSocial] = useState<ISocial>({
        email:'',
        twitter:'',
        telegramRu:'',
        telegramEn:'',
        youtube:'',
        discord:'',
        instagram:'',
        tikTok:'',
        linktree:'',
    }) 

    const closeModals = () : void => {
        setIsPolicy(false)
        setIsTerms(false)
        setIsDisclaimer(false)
        setIsCareers(false)
    }

    const confirmUpdate = async () : Promise<void> => {
        await updateFooter({legal,social})
        closeModals()
        reloadWindow()
    }

    useEffect(() => {
        const legalData : ILegal | undefined = data?.data?.footer.legal

        const socialData : ISocial | undefined = data?.data?.footer.social

        if(legalData) setLegal(legalData)

        if(socialData) setSocial(socialData)
    },[data])

    if(loading) return <Loader/>
    
    return (
        <>
        <div className={wrapper}>
            <div className={column}>
                <div className={subTitle}>
                    Social 
                </div>
                <div className={inputWrapper}>
                        <p className={label}>Email</p>
                        <input
                        value={social.email}
                        onChange={(e) => inputsHandler('email',e.target.value,setSocial)}
                        className={input}
                        type="text"
                        placeholder='company mail'
                        />
                </div>
                <div className={inputWrapper}>
                        <p className={label}>Twitter</p>
                      <input
                        value={social.twitter}
                        onChange={(e) => inputsHandler('twitter',e.target.value,setSocial)}
                        className={input}
                        type="text"
                        placeholder='twitter.com/profileuser'
                        />
                </div>
                <div className={inputWrapper}>
                        <p className={label}>Teleg. Chat</p>
                        <input
                        value={social.telegramRu}
                        onChange={(e) => inputsHandler('telegramRu',e.target.value,setSocial)}
                        className={input}
                        type="text"
                        placeholder='tg.ru/profileuser'
                        />
                </div>
                <div className={inputWrapper}>
                    <p className={label}>Teleg. Channel</p>
                        <input
                        value={social.telegramEn}
                        onChange={(e) => inputsHandler('telegramEn',e.target.value,setSocial)}
                        className={input}
                        type="text"
                        placeholder='tg.ru/profileuser'
                        />
                </div>
                <div className={inputWrapper}>
                    <p className={label}>Youtube</p>
                        <input
                        value={social.youtube}
                        onChange={(e) => inputsHandler('youtube',e.target.value,setSocial)}
                        className={input}
                        type="text"
                        placeholder='youtube.com/profileuser'
                        />
                </div>
                <div className={inputWrapper}>
                    <p className={label}>Discord</p>
                        <input
                        value={social.discord}
                        onChange={(e) => inputsHandler('discord',e.target.value,setSocial)}
                        className={input}
                        type="text"
                        placeholder='https://ds.com'
                        />
                </div>
                <div className={inputWrapper}>
                    <p className={label}>Instagram</p>
                        <input
                        value={social.instagram}
                        onChange={(e) => inputsHandler('instagram',e.target.value,setSocial)}
                        className={input}
                        type="text"
                        placeholder='https://instagram.com'
                        />
                </div>
                <div className={inputWrapper}>
                    <p className={label}>Tik Tok</p>
                        <input
                        value={social.tikTok}
                        onChange={(e) => inputsHandler('tikTok',e.target.value,setSocial)}
                        className={input}
                        type="text"
                        placeholder='https://tik-tok.com'
                        />
                </div>
                <div className={inputWrapper}>
                    <p className={label}>Linktree</p>
                        <input
                        value={social.linktree}
                        onChange={(e) => inputsHandler('linktree',e.target.value,setSocial)}
                        className={input}
                        type="text"
                        placeholder='https://linktree.com'
                        />
                </div>
                <Button
                    className={button}
                    onClick={confirmUpdate}
                    type='fill'
                >
                    Confirm
                </Button>
            </div>
            <div className={legalItems}>
                <div className={legalItem}>
                    <div className={subTitle}>
                        Privacy Policy
                    </div>
                    <Button
                        className={button}
                        onClick={() => setIsPolicy(true)}
                        type='fill'
                    >
                        Edit 
                    </Button>
                </div>
                <div className={legalItem}>
                    <div className={subTitle}>
                        Terms of use
                    </div>
                    <Button
                        className={button}
                        onClick={() => setIsTerms(true)}
                        type='fill'
                    >
                        Edit 
                    </Button>
                </div>
                <div className={legalItem}>
                    <div className={subTitle}>
                        Disclaimer
                    </div>
                    <Button
                        className={button}
                        onClick={() => setIsDisclaimer(true)}
                        type='fill'
                    >
                        Edit 
                    </Button>
                </div>
                <div className={legalItem}>
                    <div className={subTitle}>
                        Carrers
                    </div>
                    <Button
                        className={button}
                        onClick={() => setIsCareers(true)}
                        type='fill'
                    >
                        Edit 
                    </Button>
                </div>
            </div>
        </div>
        {
            isPolicy
            ?
            <TextModal
            value={legal.policy}
            title='Privacy Policy'
            onClose={() => setIsPolicy(false)}
            onChange={(value,name) => inputsHandler('policy',value,setLegal)}
            onClick={confirmUpdate}
            />
            :
            <></>
        }
        {
            isTerms
            ?
            <TextModal
            value={legal.terms}
            title='Terms of use'
            onClose={() => setIsTerms(false)}
            onChange={(value,name) => inputsHandler('terms',value,setLegal)}
            onClick={confirmUpdate}
            />
            :
            <></>
        }
        {
            isDisclaimer
            ?
            <TextModal
            value={legal.disclaimer}
            title='Disclaimer'
            onClose={() => setIsDisclaimer(false)}
            onChange={(value,name) => inputsHandler('disclaimer',value,setLegal)}
            onClick={confirmUpdate}
            />
            :
            <></>
        }
        {
            isCareers
            ?
            <TextModal
            value={String(legal.careers)}
            title='Careers'
            onClose={() => setIsCareers(false)}
            onChange={(value,name) => inputsHandler('careers',value,setLegal)}
            onClick={confirmUpdate}
            />
            :
            <></>
        }
        </>
    );
};

export default FooterLayout;