import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    preHeaderWrapper: {
        marginTop: 16,
    },
    preHeaderRightWrapper: {
        display: 'flex',
        marginBottom: 20,
        gap: 20,
        justifyContent: 'flex-end',
    },
    editMainWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        color: '#2082EA',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            }
        }
    },
    shareWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        color: '#04A584',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#04A584'
            }
        }
    },
    headerWrapper: {
        display: 'flex',
        gap: 14,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    leftHeaderWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    projectWrapper: {
        display: 'flex',
        gap: 16,
        alignItems: 'center',
    },
    projectImage: {
        width: 64,
        borderRadius: 100,
    },
    projectTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        marginBottom: 4,

        '& h1': {
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '39px',
        }
    },
    projectDescriptionWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    projectDescription: {
        color: '#738094',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '21px',
    },
    socialNetworksWrapper: {
        marginLeft: 8,
        display: 'flex',
        gap: 8,
    },
    projectDataWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },
    projectDataCellTitle: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '29px',
    },
    projectDataCellDescription: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: 6,

        '& svg': {
            width: 16,
            height: 16,
            '&  path': {
                fill: '#2082EA',
            }
        }
    },
    projectDataActionsWrapper: {
        display: 'flex',
        marginLeft: 8,
    },
    bioWrapper: {
        marginBottom: 10,
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '21px',

        '& span': {
            color: '#738094',
        },

        '& svg': {
            width: 16,
            cursor: 'pointer',

            '& path': {
                fill: '#2082EA',
            }
        },
    },
    followersWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 27,
    },
    guideWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 9,
        fontWeight: 400,
        lineHeight: '16px',
        fontSize: '14px',
        color: '#738094',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA',
            }
        }
    },
    articleWrapper: {
        width: 623,
        margin: '0 auto 60px',

        '& img': {
            width: '100%',
            marginTop: 13,
            marginBottom: 37,
        }
    },
    articleText: {
        color: '#738094',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '21px',
    },
    articleActionsWrapper: {
        marginTop: 29,
        display: 'flex',
        gap: 16,

        '& button': {
            width: 304,
        }
    },
    progressTitle: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        marginTop: 19,

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA',
            }
        }
    },
    progressValue: {
        background: 'linear-gradient(270deg, #04A584 0%, #00C099 100%)',
        borderRadius: 8,
        height: 8,
        width: '100%',
        marginTop: 8,
        marginBottom: 13,
    },
    progressValueDescription: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        gap: 6,

        '& i': {
            color: '#738094',
        },

        '& span': {
            color: '#00C099',
        }
    },
    projectDescWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '2px solid #F8F8F9',
        width: '100%',
        paddingBottom: 23,
        gap: 33,

        '&>div': {
            width: '48%',
        }
    },
    contractWrapper: {
        cursor: 'pointer',
        padding: '6px 8px',
        display: 'flex',
        alignItems: 'center',
        background: '#F3F4F6',
        borderRadius: 8,
        gap: 4,

        '& svg path': {
            fill: '#738094',
        }
    },
    mainContentHeader: {
        marginTop: 24,
        marginBottom: 28,
        display: 'flex',
        gap: 16,

        '& > div': {
            width: 'calc(50% - 10)'
        }
    },
    mainContentLeftWrapper: {
        padding: 16,
        borderRadius: 8,
        background: '#F8F8F9',
        border: '1px solid rgba(83, 98, 124, 0.07)',
    },
    mainContentRightWrapper: {
        padding: 16,
        borderRadius: 8,
        background: 'rgba(0, 192, 153, 0.05)',
        border: '1px solid rgba(83, 98, 124, 0.07)',
    },
    mainContentTitle: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: 8,

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA',
            }
        }
    },
    mainContentDescription: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#738094',
        marginBottom: 16,

        '& a': {
            color: '#04A584',
        }
    },
    mainContentTimerTitle: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#738094',
        marginBottom: 4,
    },
    mainContentTimerValue: {
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '22px',
        color: '#00C099',
    },
    tokenWrapper: {
        marginTop: 40,
    },
    tokenTitle: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
        color: '#738094',
        marginBottom: 24,
    },
    tokenDataWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 13,
    },
    tokenRow: {
        display: 'flex',
        gap: 12,
        width: '100%',
    },
    tokenLeftRow: {
        width: 230,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 6,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#738094',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA',
            }
        }
    },
    tokenRightRow: {
        width: 429,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
    },
})