import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setItems } from "../../../store/slices/searchMessagesSlice"
import useFetch from "../../hooks/useFetch"
import getAccessToken from "../../utils/getAccessToken"
import Header from "./support_list_layout/header"
import SupportTable from "./support_list_layout/support_table"

const SupportItems = () => {
  const dispatch = useDispatch()
  const {loading,data} = useFetch(
    `support`,
    {headers:{'Authorization': `Bearer ${getAccessToken()}`}},
  )

  useEffect(() => {
    if(!data?.data) return 

    dispatch(setItems(data.data))
  },[data])

  return (
    <>
    <Header/>
    <SupportTable
    loading={loading}
    />
    </>
  )
}

export default SupportItems
 