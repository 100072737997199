import { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getActionsData } from '../../components/services/confirm/getActions';
import { IAction } from '../../components/types/global_types';
import Layout from '../../components/layouts/main_layout/layout';
import Header from '../../components/layouts/confirming_layout/header';
import ConfirmingTable from '../../components/layouts/confirming_layout/table';
import sortByDate from '../../components/utils/sortByDate';

export interface IActionsFilters {
    category:Array<
        {name:'projects',isSelected:boolean} 
        | 
        {name:'news',isSelected:boolean} 
        | 
        {name:'events',isSelected:boolean} 
        | 
        {name:'persons',isSelected:boolean} 
        | 
        {name:'funds',isSelected:boolean} 
    >
    page:Array<
        {name:'crypto',isSelected:boolean} 
        | 
        {name:'earlyland',isSelected:boolean} 
        | 
        {name:'gemslab',isSelected:boolean} 
        | 
        {name:'nfts',isSelected:boolean} 
    >
    type:Array<
        {name:'Update',isSelected:boolean} 
        | 
        {name:'Create',isSelected:boolean} 
    >
}

const filtersInitial : IActionsFilters 
=
{
    category:
    [
        {name:'projects',isSelected:true},
        {name:'events',isSelected:true},
        {name:'news',isSelected:true},
        {name:'persons',isSelected:true},
        {name:'funds',isSelected:true},
    ],
    page:[
        {name:'crypto',isSelected:true},
        {name:'earlyland',isSelected:true},
        {name:'gemslab',isSelected:true},
        {name:'nfts',isSelected:true}
    ],
    type:[
        {name:'Update',isSelected:true}, 
        {name:'Create',isSelected:true}, 
    ]
}

export const ConfirmingContext = createContext<{
    actions:Array<IAction>,
    loading:boolean,
    searchValue:string,
    totalSelected:number,
    filters:IActionsFilters,
    selectToggleAction?:(id:string) => void
    toggleAllActions?:(value:boolean) => void
    searchAction?:(value:string) => void
    sortItems?:(option:string) => void
    filterItems?:(filters:IActionsFilters) => void
    setFilters?:any,
    refetch?:any
}>({
    actions:[],
    loading:false,
    searchValue:'',
    totalSelected:0,
    filters:filtersInitial
})

const ConfirmingPage = () => {
    const [filters, setFilters] = useState<IActionsFilters>(filtersInitial);
    const [totalSelected,setTotalSelected] = useState<number>(0)
    const [searchValue,setSearchValue] = useState<string>('')
    const [actionsList,setActionsList] = useState<Array<IAction>>([])
    const {data,isLoading,refetch} = useQuery('actions',getActionsData,{
        onSuccess: (fetchedData) => {
            setActionsList(fetchedData);
        },
        refetchOnWindowFocus:false
    })

    const selectToggleAction = (id:string) : void => {
        const updatedActions : Array<IAction> = []
        let totalSelect = 0

        for (let i = 0; i < actionsList.length; i++) {
            const action : IAction = actionsList[i];
      
            if(action._id === id) {
                updatedActions.push({...action,selected:!action.selected})
                !action.selected && totalSelect++
                continue
            }

            action.selected && totalSelect++
            updatedActions.push(action)
        }

        setTotalSelected(totalSelect)
        setActionsList(updatedActions)
    }

    const toggleAllActions = (value:boolean) : void => {
        setTotalSelected(value ?  actionsList.length : 0)

        setActionsList((prev:Array<IAction>) => {
            return prev.map((item:IAction) => {
                return {...item,selected:value}
            })
        })
    }
  
    const applyFiltersAndSearch 
    = 
    (data: Array<IAction>, search: string, filterOptions: IActionsFilters) 
    : 
    Array<IAction> => {
        let filteredData = data;

        filteredData = filteredData.filter((item: IAction) => {
            const isCategory = !!filterOptions.category.find((value: { name: string, isSelected: boolean }) => {
                return value.isSelected && value.name === item.category;
            });
            const isPage = !!filterOptions.page.find((value: { name: string, isSelected: boolean }) => {
                return value.isSelected && item.type?.toLowerCase().includes(value.name.toLowerCase());
            });
            const isActionType = !!filterOptions.type.find((value: { name: string, isSelected: boolean }) => {
                return value.isSelected && item.actionType?.toLowerCase().includes(value.name.toLowerCase());
            });
            
            return isCategory && isPage && isActionType;
        });

        if (search) {
            filteredData = filteredData.filter((item: IAction) => {
                const itemSearchKey: string = item?.categoryData?.name || item?.categoryData?.title;
                return itemSearchKey.toLowerCase().includes(search.toLowerCase());
            });
        }

        return filteredData;
    };

    const searchAction = (value: string): void => {
        setSearchValue(value);
        const filteredData = applyFiltersAndSearch(data || [], value, filters);
        setActionsList(filteredData);
    };

    const sortItems = (option:string) : void => {
        const sortedActions = [...actionsList];
        
        if(option === 'a' || option === 'z') {
            sortedActions.sort((a, b) => {
                const nameA = (a.categoryData?.name || a.categoryData?.title || '').toLowerCase();
                const nameB = (b.categoryData?.name || b.categoryData?.title || '').toLowerCase();
    
                if (option === 'a') {
                    return nameA.localeCompare(nameB);
                } else if (option === 'z') {
                    return nameB.localeCompare(nameA);
                } else {
                    return 0;
                }
            });
            setActionsList(sortedActions);
            return 
        }

        if(option === 'new' || option === 'old') {
            sortedActions.sort((a, b) => {
                const dateA = new Date(a.date).getTime();
                const dateB = new Date(b.date).getTime();
                if (option === 'new') {
                    return dateB - dateA;
                } else if (option === 'old') {
                    return dateA - dateB;
                } else {
                    return 0;
                }
            });
            setActionsList(sortedActions);
        }
    }
 
    const filterItems = (filterOptions:IActionsFilters) : void => {
        setFilters(filterOptions);
        const filteredData = applyFiltersAndSearch(data || [], searchValue, filterOptions);
        setActionsList(filteredData);
    }

    return (
        <ConfirmingContext.Provider value={{
            actions:actionsList,
            loading:isLoading,
            searchValue,
            totalSelected,
            selectToggleAction,
            searchAction,
            toggleAllActions,
            sortItems,
            filterItems,
            refetch,
            filters,
            setFilters
        }}>
            <Layout>
                <Header />
                <ConfirmingTable />
            </Layout>
        </ConfirmingContext.Provider>
    );
};

export default ConfirmingPage;