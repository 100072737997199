import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        background: 'white',
        border: '1px solid #EEEEEE',
        borderLeft: 'none',
        borderRight: 'none',
        cursor:'pointer'
    },
    rowWrapper: {
        padding: '16px 23px',
        display: 'flex',
        alignItems: 'center',
    },
    checkboxWrapper: {
        width: 40,
    },
    userWrapper: {
        width: 150,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',

        '& img': {
            width: 32,
            borderRadius: 100,
        },
    },
    walletWrapper: {
        width: 248,
        color: '#738094',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        cursor: 'pointer',

        '& img': {
            width: 32,
            height: 32,
            borderRadius: 100,
        },
    },
    projectTitle: {
        display:'flex',
        gap:'6px',
        alignItems:'center',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',

        '& span': {
            fontWeight: 800,
            color: '#04A584'
        },
    },
    projectDescription: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#738094',
    },
    statusWrapper: {
        width: 580,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#738094',
    },
    pointsWrapper: {
        width: 135,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
    },
    actionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    redAction: {
        color: '#FF5858',
        borderColor: '#FF5858',
    }
})