import React, {FC} from 'react';
import {useStyles} from './styles';

interface Props {
    tabs: string[];
    onChange: (value: string) => void;
    activeTab: string;
}

const Tabs: FC<Props> = ({tabs, activeTab, onChange}) => {
    const {
        wrapper,
        valueWrapper,
        activeValue,
    } = useStyles()

    return (
        <div className={wrapper}>
            {tabs.map((item, i) => (
                <div
                    className={`${valueWrapper} ${activeTab === item ? activeValue : ''}`}
                    onClick={() => onChange(item)}
                    key={i}
                >
                    {item}
                </div>
            ))}
        </div>
    );
};

export default Tabs;