import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    rowWrapper: {
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        boxShadow: '4px 4px 0px #EEEEEE',
        border: '1px solid rgba(83, 98, 124, 0.07)',
        borderRadius: 8,

        '& > div': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
        },
    },
    assetWrapper: {
        width: 134,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    tokenWrapper: {
        width: 143,

        '& > span': {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            color: '#738094',
        },
    },
    publicWrapper: {
        width: 143,

        '& > span': {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            color: '#738094',
        },
    },
    seedWrapper: {
        width: 143,

        '& > span': {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            color: '#738094',
        },
    },
    privateWrapper: {
        width: 143,

        '& > span': {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            color: '#738094',
        },
    },
    strategicWrapper: {
        width: 143,

        '& > span': {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
            color: '#738094',
        },
    },
    stageWrapper: {
        width: 138,
    },
    upcomingWrapper: {
        width: 102,
    },
    lastWrapper: {
        width: 'max-content',
    },
    imageStyle: {
        borderRadius: 100,
    },
})