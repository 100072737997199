import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { filterItems } from '../../../store/slices/searchSlice';
import useFetch from '../../hooks/useFetch';
import {FilterIcon} from '../../../assets'
import {
    CheckboxWrapper, DropdownWrapper,
    FilterButton,
    FilterWrapper, Row,
} from './styles';
import Checkbox from '../checkbox';
import Range from '../range';
import Typography from '../typography';
import ModalSelectProject from '../components_for_modals/modal_select-project';

export type ProjectPageFilters = 'project' | 'earlyland' | 'nfts' | 'compendium'
export type ProjectValidationFilters = 'Active' | 'Admin' | 'Moderator'

export interface IFilterOptions {
    totalRaised:{from:number,to:number},
    projectTypes:Array<ProjectPageFilters>,
    projectValidation:Array<ProjectValidationFilters>,
    selectedFund:any
}

const Filter = () => {
    const dispatch = useDispatch()
    const {data} = useFetch('funds')
    const [isOpen, setIsOpen] = useState(false)
    const [options,setOptions] = useState<IFilterOptions>({
        totalRaised:{from:0,to:10000000},
        projectTypes:['project','earlyland','nfts'],
        projectValidation:['Active','Admin','Moderator'],
        selectedFund:'ALL'
    })

    const filterInputsHandler = (name:string,value:any) => {
        setOptions((prev) => {
            return (
                {...prev,[name]:value}
            )
        })
    }

    const projectTypesFilterHandler = (value:ProjectPageFilters) => {
        if(options.projectTypes.includes(value)){
            setOptions((prev:IFilterOptions) => {
                return ({
                    ...prev,
                    projectTypes:options.projectTypes.filter((oldValue:ProjectPageFilters) => oldValue !== value)
                })
            })
        }else{
            setOptions((prev:IFilterOptions) => {
                return ({
                    ...prev,
                    projectTypes:[...options.projectTypes,value]
                })
            })
        }
    }

    const projectValidationFilterHandler = (value:ProjectValidationFilters) => {
        if(options.projectValidation.includes(value)){
            setOptions((prev:IFilterOptions) => {
                return ({
                    ...prev,
                    projectValidation:options.projectValidation.filter((oldValue:ProjectValidationFilters) => oldValue !== value)
                })
            })
        }else{
            setOptions((prev:IFilterOptions) => {
                return ({
                    ...prev,
                    projectValidation:[...options.projectValidation,value]
                })
            })
        }
    }

    useEffect(() => {
        dispatch(filterItems(options))    
    },[options])

    return (
        <FilterWrapper >
            <FilterButton onClick={() => setIsOpen(state => !state)}>
                <FilterIcon fill="#00C099" />
                <Typography variant='p'>
                    Filters
                </Typography>
            </FilterButton>
            {isOpen && <DropdownWrapper>
                <Row>
                    <p>Page</p>
                    <CheckboxWrapper>
                        <Checkbox
                            onChange={() => projectTypesFilterHandler('project')}
                            active={options.projectTypes.includes('project')}
                            labelValue='Crypto'
                        />
                        <Checkbox
                            onChange={() => projectTypesFilterHandler('nfts')}
                            active={options.projectTypes.includes('nfts')}
                            labelValue="NFT's"
                        />
                        <Checkbox
                            onChange={() => projectTypesFilterHandler('earlyland')}
                            active={options.projectTypes.includes('earlyland')}
                            labelValue='EarlyLand'
                        />
                        <Checkbox
                            onChange={() => projectTypesFilterHandler('compendium')}
                            active={options.projectTypes.includes('compendium')}
                            labelValue='Compendium'
                        />
                    </CheckboxWrapper>
                </Row>
                <Row>
                    <p>Validation status</p>
                    <CheckboxWrapper>
                        <Checkbox
                            onChange={() => projectValidationFilterHandler('Active')}
                            active={options.projectValidation.includes('Active')}
                            labelValue='Active'
                        />
                        <Checkbox
                            onChange={() => projectValidationFilterHandler('Admin')}
                            active={options.projectValidation.includes('Admin')}
                            labelValue="Admin"
                        />
                        <Checkbox
                            onChange={() => projectValidationFilterHandler('Moderator')}
                            active={options.projectValidation.includes('Moderator')}
                            labelValue='Moderator'
                        />
                    </CheckboxWrapper>
                </Row>
                <Row>
                    <p>Total raised</p>
                    <Range
                        min={0}
                        max={10000000}
                        values={[options.totalRaised.from, options.totalRaised.to]}
                        step={1}
                        onChange={(values) => 
                            filterInputsHandler('totalRaised',{from:values[0],to:values[1]})
                        }
                    />
                </Row>
                <Row>
                    <p>Fund</p>
                    {/* <ModalSelect
                        label=''
                        items={{
                            
                        }['fund','fund','fund','fund']}
                        onChange={(value) => console.log(value)}
                        value={'fund'}
                    /> */}
                    <ModalSelectProject
                    items={data?.data ? ['ALL',...data.data] : []}
                    label=''
                    name={options.selectedFund ? options.selectedFund.name : 'ALL'}
                    project={options.selectedFund ? options.selectedFund : {}}
                    onChange={(fund:any) => filterInputsHandler('selectedFund',fund)}
                    />
                </Row>
            </DropdownWrapper>}
        </FilterWrapper>
    );
};

export default Filter;