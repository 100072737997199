import { useContext } from 'react';
import { ProjectContext } from '../../../../../pages/Gemslab/Projects';
import { useDispatch,useSelector } from 'react-redux';
import { setItems } from '../../../../../store/slices/searchSlice';
import TableRow from './table_row';
import {useStyles} from './styles';
import useFetch from '../../../../hooks/useFetch';
import { IProject } from '../../../../hooks/useCreateProject';
import Loader from '../../../../common/loader';
import getAccessToken from '../../../../utils/getAccessToken';
import getUserRole from '../../../../utils/getUserRole';

const ProjectTable = () => {
    const {
        wrapper,
        headerWrapper,
        projectsCell,
        statusCell,
        investorsCell,
        raisedCell,
        fundingCell,
        typeCell,
        priceCell,
        flagsCell,
    } = useStyles()
    const dispatch = useDispatch()
    const {projects} = useContext(ProjectContext)
    
    return (
        <>
            <div className={wrapper}>
                <div className={`${headerWrapper} container`}>
                    <div className={projectsCell}>
                        Projects
                    </div>
                    <div className={statusCell}>
                        Status
                    </div>
                    <div className={investorsCell}>
                        Investors
                    </div>
                    <div className={raisedCell}>
                        Total Raised
                    </div>
                    <div className={fundingCell}>
                        Last Funding
                    </div>
                    <div className={typeCell}>
                        Type
                    </div>
                    <div className={priceCell}>
                        Price
                    </div>
                    <div className={flagsCell}>
                        Red flags
                    </div>
                </div>
            </div>
            <div>
                {
                    projects?.length
                    ?
                    projects.map((project:IProject) => {
                        return <TableRow key={project._id} project={project}/>
                    })
                    :
                    <></>
                }
            </div>
        </>
    );
};

export default ProjectTable;