import styled from 'styled-components';


export const ColumnWrapper = styled.div`
    position: relative;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #04A584;
    min-width: 350px;

`

export const ColumnRows = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
`

export const ColumnTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
`

export const RowItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    border: 1px solid gray;
    border-radius: 4px;
`

export const DeleteColumnWrapper = styled.div`
    position: absolute;
    top: 10px;
    right:10px;
`

export const SelectedIcon = styled.div`
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #04A584;
    min-width: 28px;
    height: 28px;
`

export const StatusBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: 6px;
`


export const StatusLabel = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgb(115, 128, 148);
    margin-bottom: 7px;
    margin-top: 7px;
`

export const StatusListWrapper = styled.div`
    display:flex;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 12px;
`