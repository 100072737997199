import React from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import OTCLayout from '../../components/layouts/projects_layouts/otc_layout';

const OTCPage = () => {
    return (
        <Layout>
            <OTCLayout />
        </Layout>
    );
};

export default OTCPage;