import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        marginTop: 16,
        display: 'flex',
        gap: 32,

        '& > div': {
            width: 'calc(50% - 16px)',
        }
    },
    imageWrapper: {

        '& img': {
            width: '100%',
            borderRadius: 24,
        },
    },
    userDataWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',

        '& img': {
            width: 20,
            height: 20,
            borderRadius: 100,
        }
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent :'space-between',
        marginBottom: 12,
    },
    contentHeaderEditWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        cursor: 'pointer',
        color: '#2082EA',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        }
    },
    projectTitleWrapper: {
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '39px',
        display: 'flex',
        alignItems: 'center',
        gap: 9,
        marginBottom: 8,

        '& span': {
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '17px',
            color: '#738094',
            background: 'rgba(115, 128, 148, 0.1)',
            padding: '4px 10px',
            borderRadius: 8,
        }
    },
    projectDescription: {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '21px',

        '& span': {
            color: '#738094',
        }
    },
    projectPriceWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        marginTop: 24,

        '& img': {
            width: 40,
            height: 40,
            borderRadius: 100,
        }
    },
    projectPriceTitle: {
        color: '#738094',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        marginBottom: 3,

        '& span': {
            fontWeight: 600,
        }
    },
    projectPriceValue: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '29px',
    },
    creatorWrapper: {
        marginTop: 34,
        padding: 16,
        border: '1px solid rgba(83, 98, 124, 0.07)',
        boxShadow: '4px 4px 0px #EEEEEE',
        borderRadius: 8,
        width: 'max-content',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        }
    },
    creatorTitle: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        marginBottom: 3,
    },
    creatorRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,
        padding: '16px 0',
        borderBottom: '2px solid #F8F8F9',
    },
    creatorRowTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        color: '#738094',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
    },
    creatorRowValue: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
    },
})