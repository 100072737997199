import React, {useState} from 'react';
import EditIcon from '../../../../common/Icons/edit_icon';
import {useStyles} from './styles';
import Button from '../../../../common/button';
import NewsItem from './news_item';
import DescriptionModal from '../../../../common/global_modals/description_modal';
import CreateNewsModal from '../../modals/create_news_modal';
import { INews } from '../../../../types/global_types';

const MarketTab = ({news}:{news:Array<INews>}) => {
    const [isBioModal, setIsBioModal] = useState(false)
    const [isNewsModal, setIsNewsModal] = useState(false)

    const {
        descriptionWrapper,
        newsWrapper,
    } = useStyles()

    return (
        <div>
            <div className={descriptionWrapper}>
                <Button onClick={() => setIsNewsModal(true)} type='bordered'>
                    Add news
                </Button>
                </div>
                <div className={newsWrapper}>
                    {
                        news?.length
                        ?
                        news.map((newsItem:INews) => {
                            return <NewsItem key={newsItem._id} news={newsItem}/>
                        })
                        :
                        <></>
                    }
                </div>
                {isBioModal && <DescriptionModal onClose={() => setIsBioModal(false)} />}
                {isNewsModal && <CreateNewsModal onClose={() => setIsNewsModal(false)} />}
        </div>
    );
};

export default MarketTab;