import styled from 'styled-components';
import Button from '../../../../common/button';

export const ModalRow = styled.div`
  margin-top: 20px;
  p{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #738094;
    margin-bottom: 7px;
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`

export const UsersRow = styled.div`
  margin-top: 20px;
  
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #738094;
    margin-bottom: 7px;
  }
  
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
`