export enum SETTINGS_TABS {
    MODERATORS = 'Moderators',
    PASSWORD = 'Password',
    FOOTER = 'Footer',
    FAQ = 'FAQ',
}

export const settingsTabsArray = [
    SETTINGS_TABS.MODERATORS,
    SETTINGS_TABS.PASSWORD,
    SETTINGS_TABS.FOOTER,
    SETTINGS_TABS.FAQ,
]