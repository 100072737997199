import SupportItems from "../../components/layouts/support_layout"
import Layout from "../../components/layouts/main_layout/layout"

const Support = () => {
  return (
    <Layout>
      <SupportItems/>
    </Layout>
  )
}

export default Support
