import React, {FC, ReactNode, useEffect, useState} from 'react';
import {NavigationLinksList} from '../../../static_content/navigation_data';
import {NavLink} from 'react-router-dom';
import { useHistory ,useRouteMatch} from 'react-router';
import logo from '../../../assets/img/logo_header.png'
import avatar from '../../../assets/img/avatar.png'
import {useStyles} from './styles';
import ArrowDownIcon from '../../common/Icons/arrow_down_icon';
import logout from '../../services/auth/logout';
import loader from '../../services/loader';

interface Props {
    children: ReactNode;
}

const Layout: FC<Props> = ({children}) => {
    const {
        wrapper,
        logoWrapper,
        linksWrapper,
        linkTitle,
        linkDropdownWrapper,
        linkWrapper,
        dropdownWrapper,
        openDropdown,
        logoutWrapper,
        userAvatar,
        logoutButton,
        mainWrapper,
        head
    } = useStyles()
    const [userRole,setUserRole] = useState<string>('')
    const [userData,setUserData] = useState<any>()
    const navigation = useHistory()

    const confirmLogout = () : void => {
        logout()
        navigation.push('/login')
    }

    const {path} = useRouteMatch()

    const [activeDropdown, setActiveDropdown] = useState('')

    const handleDropdown = (name: string) => {
        if (activeDropdown === name) {
            setActiveDropdown('')
        } else {
            setActiveDropdown(name)
        }
    }

    useEffect(() => {
        const role : string | null = localStorage.getItem('fomoRole')
        const user : any = localStorage.getItem('fomoUser')

        role && setUserRole(role)
        user && setUserData(JSON.parse(user))

        scrollTo(0, 0)
    }, [])

    return (
        <div className={mainWrapper}>
            <div className={wrapper}>
                <div className={head}>
                    <div className={logoWrapper}>
                        <img src={logo} alt="logo"/>
                    </div>
                    <span>{userRole}</span>
                </div>
                <div className={linksWrapper}>
                    {NavigationLinksList.map((item, i) => (
                        <div
                            key={i} className={linkWrapper}
                            onMouseLeave={() => setActiveDropdown('')}
                        >
                            <div className={linkTitle}>
                                {!item.isDropdown ? (
                                    <NavLink to={item.link}>
                                        {item.title}
                                    </NavLink>
                                ) : (
                                    <div
                                        className={`
                                            ${activeDropdown === item.title && openDropdown} 
                                            ${linkDropdownWrapper} 
                                            ${path.includes(item.link) ? 'active' : ''
                                        }`}
                                        onClick={() => handleDropdown(item.title)}
                                    >
                                        {item.title}
                                        <ArrowDownIcon />
                                    </div>
                                )}
                            </div>
                            {activeDropdown === item.title && item.isDropdown && (
                                <div className={dropdownWrapper}>
                                    {item.links.map((link, i) => (
                                        <NavLink exact to={`${item.link}${link.link}`} key={i}>
                                            {link.title}
                                        </NavLink>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                    <div className={linkWrapper}>
                        <div
                            onClick={() => handleDropdown('logout')}
                            className={linkDropdownWrapper}
                        >   
                            {
                                userData?.photo || userData?.twitterData?.photo 
                                ?
                                <img 
                                className={userAvatar} 
                                src={
                                    userData?.photo 
                                    ?
                                    loader(userData?.photo)
                                    :
                                    userData?.twitterData?.photo
                                } 
                                alt="user"
                                />
                                :
                                <img className={userAvatar} src={avatar} alt="user"/>
                            }
                            <ArrowDownIcon />
                        </div>
                        {activeDropdown === 'logout' && (
                            <div className={`${dropdownWrapper} ${logoutWrapper}`}>
                                <button onClick={confirmLogout} className={logoutButton}>
                                    Log out
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};

export default Layout;