import styled from 'styled-components';

export const ModalRow = styled.div`
  margin-top: 20px;
  
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #070B35;
  }
`

export const AddButton = styled.button`
  background: none;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #05C9A1;
`

export const FlagRow = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 8px;
  
  span  {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #738094;
  }
  
  input {
    background: #F8F8F9;
    border-radius: 8px;
    padding: 10px;
    width: 252px;
    border: none;
    margin-bottom: 8px;
    
    &:first-child {
      width: 224px;
    }
    &:last-child {
      width: 39px;
    }
  }
`

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #070B35;
  }
`

export const Total = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #738094;
  margin-top: 12px;
`