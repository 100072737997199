import styled from 'styled-components';

export const Wrapper = styled.button`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(83, 98, 124, 0.07);
  box-shadow: 4px 4px 0 #EEEEEE;
  border-radius: 8px;
  margin-bottom: 10px;
  width:100%;
  transition: all 0.3s ease;
  &:hover{
    opacity: 0.9;
    background: #8080802f;
  }
  &:active{
    opacity: 0.7;
    background: #8080802f;
  }
`

export const ProjectWrapper = styled.div`
  width: 230px;
  display: flex;
  gap: 10px;
  align-items: center;
  
  img {
    width: 32px;
    height: 32px;
    border-radius: 100px;
  }
`
export const ProjectTitleWrapper = styled.div`
  display: flex;
  
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-right: 4px;
  }
  span {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #04A584;
  }
`
export const ProjectDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #738094;
`
export const StatusWrapper = styled.div`
  width: 130px
`
export const EventWrapper = styled.a`
  width: 175px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  &:hover{
    opacity: 0.9;
    background: #8080802f;
  }
  &:active{
    opacity: 0.7;
    background: #8080802f;
  }
`
export const DateWrapper = styled.div`
  width: 202px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`
export const TimeWrapper = styled.div`
  width: 130px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  gap: 10px;
  
  div {
    display: flex;
    flex-direction: column;
  }
  
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #738094;
  }
`

export const RatingWrapper = styled.div`
  width: 163px;
`

export const ActionsWRapper = styled.div`
  position: absolute;
  top: 8px;
  right: 5px;
  display: flex;
  align-items: center;
  gap: 16px;
`

export const PointsWrapper = styled.div`
  font-weight: 600;
`

export const TableItemWrapper = styled.div`
  position: relative;
`