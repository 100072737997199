import React from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import Header from '../../components/layouts/info_layout/header';
import InfoLayout from '../../components/layouts/info_layout/InfoLayout';

const InfoPage = () => {
    return (
        <Layout>
            <Header/>
            <InfoLayout/>
        </Layout>
    );
};

export default InfoPage;