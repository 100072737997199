import React, {FC,useState} from 'react';
import Modal from '../../../../common/modal';
import InputWithLabel from '../../../../common/components_for_modals/input_with_label';
import {ModalRow} from '../../../projects_layouts/modals/creating_project/styles';
import {SubmitButton} from '../../../../common/global_modals/description_modal/styles';
import addTwitter from '../../../../services/news/addTwitter';
import reloadWindow from '../../../../utils/reloadWindow';

interface Props {
    onClose: () => void;
}

const AddTwitterUserModal: FC<Props> = ({onClose}) => {
    const [link,setLink] = useState<string>('')

    const confrimAddAcc = async () : Promise<void> => {
        if(!link) return
        
        onClose()
        await addTwitter(link)
        reloadWindow()
    }

    return (
        <Modal
            title='Add twitter acc'
            onClose={onClose}
            variant='small'
        >
            <ModalRow>
                <InputWithLabel
                    value={link}
                    label='Enter link'
                    onChange={(value) => setLink(value)}
                />
            </ModalRow>
            <SubmitButton onClick={confrimAddAcc} type='fill'>
                Save changes
            </SubmitButton>
        </Modal>
    );
};

export default AddTwitterUserModal;