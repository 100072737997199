import {createUseStyles} from 'react-jss';

interface Props {
    status: string,
    rating: number,
}

export const useStyles = createUseStyles({
    wrapper: {
        background: ({status}: Props) => status ? 'rgba(255, 88, 88, 0.05)' : 'white',
    },
    rowWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px 23px',
        borderBottom: '1px solid #eee',
    },
    projectWrapper: {
        width: 250,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    projectImage: {
        width: 32,
    },
    projectDataWrapper: {
        display: 'flex',
    },
    projectTitle: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        gap: 6,

        '& span': {
            color: '#04A584',
        }
    },
    projectDescription: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#738094',
    },
    statusWrapper: {
        width: 110,
    },
    validationCell:{
        width: 100,
        marginRight:'20px',
        gap: '3px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 6px 4px 6px',
        borderRadius: '8px',
        textAlign:'center',
        fontSize:'14px',
        '&.moderator':{
            color: 'rgb(233, 181, 0)',
            background: 'rgba(233, 181, 0, 0.1)'
        },
        '&.active':{
            color: 'rgb(0, 192, 153)',
            background: 'rgba(0, 192, 153, 0.1)'
        }
    },
    investorsWrapper: {
        width: 255,
    },
    raisedWrapper: {
        width: 92,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
    },
    fundingWrapper: {
        width: 110,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
    },
    typeWrapper: {
        width: 81,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
    },
    tagCircle: {
        width: 16,
        height: 16,
        background: 'rgba(115, 128, 148, 0.5)',
        borderRadius: '100%',
    },
    tagWrapper: {
        width: 164,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#738094',
    },
    flagsWrapper: {
        width: 68,
    },
    ratingWrapper: {
        width: 118,
    },
    actionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 14,
    },
    dotsAction: {
        '& svg circle': {
            fill: 'rgba(115, 128, 148, 0.5)',
        }
    },
})