import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        border: '1px solid #04A584',
        background: 'white',
        borderRadius: 8,
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#04A584',
        padding: '8px 12px',
        transition:'all 0.2s ease',

        '&:hover':{
            background:'#04A584',
            color:'white',
        },
        '&:active':{
            opacity:'0.7',
        },
        '&.progress-inwork':{
            color:'#F9A353',
            border: '1px solid #F9A353',

            '&:hover':{
                background:'#F9A353',
                color:'white',
            }
        },
        '&.progress-upcoming':{
            color:'#B9C0CA',
            border: '1px solid #B9C0CA',
            
            '&:hover':{
                background:'#B9C0CA',
                color:'white',
            }
        },
        '&.decline-btn': {
            borderColor: '#FF5858',
            color: '#FF5858',
    
            '&:hover':{
                borderColor: '#FF5858',
                background: '#FF5858',
                color:'white'
            }
        },
        '&.blue-btn':{
            background:'white',
            color:'rgb(107, 177, 252)',
            border:'1px solid rgb(107, 177, 252)',
            '&:hover':{
                background: 'rgb(107, 177, 252)',
                color:'white'
            }
        }
    }
})