import {useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { setAuth , setRole} from '../store/slices/authSlice';
import { RootState } from '../store/store';
import { checkAuth } from './services/auth/checkAuth';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import {Redirect} from 'react-router';
import ProjectsPage from '../pages/Projects';
import EarlylandPages from '../pages/Earlyland';
import NFTSPages from '../pages/NFTS';
import Gemslab from '../pages/Gemslab';
import NotFoundPage from '../pages/NotFound';
import UsersList from '../pages/UsersList';
import Statistics from '../pages/Statistics';
import Confirming from '../pages/Confirming';
import Settings from '../pages/Settings';
import Person from '../pages/Person';
import LoginPage from '../pages/Login';
import ModeratorPage from '../pages/Settings/moderator';
import InfoPage from '../pages/Info';
import Main from '../pages/Main';
import Support from '../pages/Support';
import Loader from './common/loader';
import BannersPage from '../pages/Banners';

export const App = () => {
    const [loading,setLoading] = useState(true)
    const isAuth = useSelector((state : RootState) => state.auth.isAuth)
    const dispatch = useDispatch()

    useEffect(() => {
        checkAuth().then(({success}) => {
            if(!success){
                setLoading(false)
            }else{
                dispatch(setAuth(success))
                dispatch(setRole(localStorage.getItem('fomoRole')))
                setLoading(false)
            }
        })
    },[])
    
    if(loading){
        return (
            <Loader/>
        )
    }

    return (
        isAuth
        ?
        <Router>
            <Switch>
                <Route exact path='/'>
                    <Redirect to='/projects' />
                </Route>
                <Route path='/projects' component={ProjectsPage} />
                <Route path='/early_land' component={EarlylandPages} />
                <Route path='/nfts' component={NFTSPages} />
                <Route path='/gems_lab' component={Gemslab} />
                <Route path='/users_list' component={UsersList} />
                <Route path='/statistics' component={Statistics} />
                <Route path='/confirming' component={Confirming} />
                <Route path='/settings/:id' component={ModeratorPage} />
                <Route path='/settings' component={Settings} />
                <Route path='/person' component={Person} />
                <Route path='/login' component={LoginPage}/>
                <Route path='/main' component={Main}/>
                <Route path='/support' component={Support}/>
                <Route path='/info' component={InfoPage}/>
                <Route path='/banners' component={BannersPage}/>
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </Router>
        :
        <>
        <Router>
                <Route exact path='*'  >
                    <Redirect to='/login' />
                </Route>
                <Route path='/login' component={LoginPage}/>
        </Router>
        </>
    )
}
