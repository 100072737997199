import React from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import NftsProjectLayout from '../../components/layouts/nfts_layouts/project_layout';

const Project = () => {
    return (
        <Layout>
            <NftsProjectLayout />
        </Layout>
    );
};

export default Project;