import moment from 'moment';

export const OptionsForSortProjectsPage = [
    {value: 'name_a_z', name: 'Name (A-Z)'},
    {value: 'name_z_a', name: 'Name (Z-A)'},
    {value: 'date_from_new', name: 'Date (from new)'},
    {value: 'date_from_old', name: 'Date (from old)'},
]


export const CryptoCurrencies = [
    {name: 'ETH', value: 'eth'},
    {name: 'USDT', value: 'usdt'},
    {name: 'BTC', value: 'btc'},
]

export const NewsData = [
    {
        date: String(moment()),
        title: 'Amet minim',
        text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    },
    {
        date: String(moment()),
        title: 'Amet minim',
        text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    },
    {
        date: String(moment()),
        title: 'Amet minim',
        text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    },
    {
        date: String(moment()),
        title: 'Amet minim',
        text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    },
    {
        date: String(moment()),
        title: 'Amet minim',
        text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    },
    {
        date: String(moment()),
        title: 'Amet minim',
        text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.',
    },
]

export const PriceGraphic = [
    {pr: 10, date: '18.04'},
    {pr: 20, date: '19.04'},
    {pr: 15, date: '20.04'},
    {pr: 15, date: '21.04'},
    {pr: 30, date: '22.04'},
    {pr: 15, date: '23.04'},
    {pr: 25, date: '24.04'},
    {pr: 20, date: '25.04'},
    {pr: 20, date: '26.04'},
    {pr: 35, date: '28.04'},
    {pr: 20, date: '28.04'},
]

export const AssetTableData = [
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        title: 'Starly',
        images: [
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        ],
        tokenSupply: {
            percentage: 35.34,
            plus: 5170000,
        },
        publicVesting: {
            percentage: 35.34,
            left: 10,
        },
        seedVesting: {
            percentage: 35.34,
            left: 10,
        },
        privateVesting: {
            percentage: 35.34,
            left: 10,
        },
        strategicVesting: {
            percentage: 35.34,
            left: 10,
        },
        stage: 'Upcoming unlock',
        upcoming: 'Today',
        last: 'Today',
    },
]

export const ActionsTableProjects = [
    {
        id: '1',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'active',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '2',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'upcoming',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '3',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'active',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '4',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'upcoming',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '5',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'active',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '6',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'upcoming',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '7',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'active',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '8',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'upcoming',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '9',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'active',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '10',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'upcoming',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
    {
        id: '11',
        variant: 'default',
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        rating: 94,
        title: 'SharkRace Club',
        percentage: 75,
        status: 'upcoming',
        description: 'Some text',
        investors: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        redFlagsCount: 20,
        followers: 1800000,
        smScore: 1000,
    },
]

export const AssetUnlocking = [
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        title: 'Starly',
        stage: 'Upcoming unlock',
        images: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        tokenSupply: {
            percentage: 35.34,
            plus: 5170000,
        },
        publicVesting: {
            percentage: 7.17,
            left: 10,
        },
        price: {
            percentage: -0.8,
            amount: 2895141,
        },
        upcoming: 'Today',
        last: 'Today',
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        title: 'Starly',
        stage: 'Upcoming unlock',
        images: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        tokenSupply: {
            percentage: 35.34,
            plus: 5170000,
        },
        publicVesting: {
            percentage: 7.17,
            left: 10,
        },
        price: {
            percentage: 0.8,
            amount: 2895141,
        },
        upcoming: 'Today',
        last: 'Today',
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        title: 'Starly',
        stage: 'Upcoming unlock',
        images: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        tokenSupply: {
            percentage: 35.34,
            plus: 5170000,
        },
        publicVesting: {
            percentage: 7.17,
            left: 10,
        },
        price: {
            percentage: -0.8,
            amount: 2895141,
        },
        upcoming: 'Today',
        last: 'Today',
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        title: 'Starly',
        stage: 'Upcoming unlock',
        images: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        tokenSupply: {
            percentage: 35.34,
            plus: 5170000,
        },
        publicVesting: {
            percentage: 7.17,
            left: 10,
        },
        price: {
            percentage: 0.8,
            amount: 2895141,
        },
        upcoming: 'Today',
        last: 'Today',
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        title: 'Starly',
        stage: 'Upcoming unlock',
        images: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        tokenSupply: {
            percentage: 35.34,
            plus: 5170000,
        },
        publicVesting: {
            percentage: 7.17,
            left: 10,
        },
        price: {
            percentage: -0.8,
            amount: 2895141,
        },
        upcoming: 'Today',
        last: 'Today',
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        title: 'Starly',
        stage: 'Upcoming unlock',
        images: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        tokenSupply: {
            percentage: 35.34,
            plus: 5170000,
        },
        publicVesting: {
            percentage: 7.17,
            left: 10,
        },
        price: {
            percentage: 0.8,
            amount: 2895141,
        },
        upcoming: 'Today',
        last: 'Today',
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        title: 'Starly',
        stage: 'Upcoming unlock',
        images: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        tokenSupply: {
            percentage: 35.34,
            plus: 5170000,
        },
        publicVesting: {
            percentage: 7.17,
            left: 10,
        },
        price: {
            percentage: -0.8,
            amount: 2895141,
        },
        upcoming: 'Today',
        last: 'Today',
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        title: 'Starly',
        stage: 'Upcoming unlock',
        images: [
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
            {avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU', name: 'name'},
        ],
        tokenSupply: {
            percentage: 35.34,
            plus: 5170000,
        },
        publicVesting: {
            percentage: 7.17,
            left: 10,
        },
        price: {
            percentage: 0.8,
            amount: 2895141,
        },
        upcoming: 'Today',
        last: 'Today',
    },
]

export const WeekDaysShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export const CalendarEvents = [
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        name: 'Event name',
        rating: 94,
        status: 'active',
        title: 'SharkRace Club',
        percentage: 75,
        description: 'NFT & Collectibles',
        date: moment().add(10, 'days'),
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        name: 'Event name',
        rating: 94,
        status: 'active',
        title: 'SharkRace Club',
        percentage: 75,
        description: 'NFT & Collectibles',
        date: moment().add(10, 'days'),
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        name: 'Event name',
        rating: 94,
        status: 'active',
        title: 'SharkRace Club',
        percentage: 75,
        description: 'NFT & Collectibles',
        date: moment().add(10, 'days'),
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        name: 'Event name',
        rating: 94,
        status: 'active',
        title: 'SharkRace Club',
        percentage: 75,
        description: 'NFT & Collectibles',
        date: moment().add(10, 'days'),
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        name: 'Event name',
        rating: 94,
        status: 'active',
        title: 'SharkRace Club',
        percentage: 75,
        description: 'NFT & Collectibles',
        date: moment().add(10, 'days'),
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        name: 'Event name',
        rating: 94,
        status: 'active',
        title: 'SharkRace Club',
        percentage: 75,
        description: 'NFT & Collectibles',
        date: moment().add(10, 'days'),
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        name: 'Event name',
        rating: 94,
        status: 'active',
        title: 'SharkRace Club',
        percentage: 75,
        description: 'NFT & Collectibles',
        date: moment().add(10, 'days'),
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        name: 'Event name',
        rating: 94,
        status: 'active',
        title: 'SharkRace Club',
        percentage: 75,
        description: 'NFT & Collectibles',
        date: moment().add(10, 'days'),
    },
    {
        avatar: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyz-77X11MoGE22xVjjPhbpW6lPj6I0SkcTQ&usqp=CAU',
        name: 'Event name',
        rating: 94,
        status: 'active',
        title: 'SharkRace Club',
        percentage: 75,
        description: 'NFT & Collectibles',
        date: moment().add(10, 'days'),
    },
]