import Layout from '../../../components/layouts/main_layout/layout';
import FundPageLayout from '../../../components/layouts/funds_layouts/funds_layout';

const FundPage = () => {
    return (
        <Layout>
            <FundPageLayout/>
        </Layout>
    );
};

export default FundPage;