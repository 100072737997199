import getAccessToken from "../../utils/getAccessToken"
import { configureUrl } from "../config"
import { IReturnData } from "../types"


export default async (path:string,id:string,actions?:Array<any>) : Promise<IReturnData> => {
    try{
        const token : string = getAccessToken()

        if(!token){
            throw new Error('Not auth')
        }

        const responce = await fetch(configureUrl(`${path}/${id}`),{
            method:'PUT',
            headers:{
                'Authorization': `Bearer ${token}`,
                'Content-Type':'application/json'
            },
            credentials:'include',
            body:JSON.stringify({actions})
        });


        return {success:true,data:'Action confirmed'}

    }catch(error){
        console.log(error)
        return {success:false,data:error}
    }
}