import Layout from "../../components/layouts/main_layout/layout";
import MainPageLayout from "../../components/layouts/main_page_layout/MainPageLayout";

const Main = () => {
  return (
    <Layout>
        <MainPageLayout/>
    </Layout>
  )
}

export default Main;
