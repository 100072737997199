import styled from 'styled-components';
import Button from '../../../../common/button';

export const ModalRow = styled.div`
  margin-top: 20px;
`

export const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
`