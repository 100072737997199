import React, {useState} from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import HeaderLayout from '../../components/layouts/settings_layout/header_layout';
import {SETTINGS_TABS, settingsTabsArray} from '../../static_content/settings_data';
import ModeratorsLayout from '../../components/layouts/settings_layout/moderators_layout';
import PasswordLayout from '../../components/layouts/settings_layout/password_layout';
import FooterLayout from '../../components/layouts/settings_layout/footer_layout';
import CreateFAQLayout from '../../components/layouts/settings_layout/FAQ_layout';

const SettingsPage = () => {
    const [activeTab, setActiveTab] = useState(settingsTabsArray[0])

    const handleTab = (value: SETTINGS_TABS) => {
        setActiveTab(value as SETTINGS_TABS)
    }

    const handleContent = () => {
        switch (activeTab) {
            case SETTINGS_TABS.MODERATORS:
                return <ModeratorsLayout />
            case SETTINGS_TABS.PASSWORD:
                return <PasswordLayout />
            case SETTINGS_TABS.FOOTER:
                return <FooterLayout />
            case SETTINGS_TABS.FAQ:
                return <CreateFAQLayout />
            default:
                return <ModeratorsLayout />
        }
    }

    return (
        <Layout>
            <HeaderLayout
                activeTab={activeTab}
                onChange={handleTab}        
            />
            <div>
                {handleContent()}
            </div>
        </Layout>
    );
};

export default SettingsPage;