import Layout from '../../components/layouts/main_layout/layout';
import Header from '../../components/layouts/earlyland_layouts/recommendations_layout/header';
import ProjectsTable from '../../components/layouts/earlyland_layouts/recommendations_layout/projects_table';

const RecommendationPage = () => {
    return (
        <Layout>
            <Header />
            <ProjectsTable />
        </Layout>
    );
};

export default RecommendationPage;