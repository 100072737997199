import React from 'react';

const ArrowDownIcon = () => {
    return (
        <svg width="8" height="3" viewBox="0 0 8 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 3L0.535898 -1.75695e-07L7.4641 4.29987e-07L4 3Z" fill="#738094"/>
        </svg>
    );
};

export default ArrowDownIcon;