export enum NEWS_TABS_LIST {
    SMART = 'Smart',
    PARCING = 'Parcing',
    MARKET = 'Market'
}

export const newsTabsList = [
    NEWS_TABS_LIST.SMART,
    NEWS_TABS_LIST.PARCING,
    NEWS_TABS_LIST.MARKET,
]