import styled from 'styled-components';

export const InputsWrapper = styled.div`
    width:100%;
    margin:12px 0px ;
    display: flex;
    flex-direction: column;
    gap:10px;
`

export const Head = styled.div`
    margin:12px 0px ;

`

export const Key = styled.span`
    font-size: 16px;
`

export const Value = styled.span`
    font-weight: 600;
`