import React from 'react';

const DetailsCalendarIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1941_364)">
                <path d="M3.83989 0C3.48989 0 3.19989 0.29 3.19989 0.64V1.28H1.27989C0.908643 1.28 0.639893 1.54875 0.639893 1.92V4.16H15.3599V1.92C15.3599 1.54875 15.0911 1.28 14.7199 1.28H12.7999V0.64C12.7999 0.29 12.5099 0 12.1599 0H11.5199C11.1699 0 10.8799 0.29 10.8799 0.64V1.28H5.11989V0.64C5.11989 0.29 4.82989 0 4.47989 0H3.83989ZM3.83989 0.64H4.47989V2.56H3.83989V0.64ZM11.5199 0.64H12.1599V2.56H11.5199V0.64ZM0.639893 4.8V14.72C0.639893 15.0913 0.908643 15.36 1.27989 15.36H14.7199C15.0911 15.36 15.3599 15.0913 15.3599 14.72V4.8H0.639893ZM3.83989 6.72H5.43989V8.32H3.83989V6.72ZM6.07989 6.72H7.67989V8.32H6.07989V6.72ZM8.31989 6.72H9.91989V8.32H8.31989V6.72ZM10.5599 6.72H12.1599V8.32H10.5599V6.72ZM3.83989 8.96H5.43989V10.56H3.83989V8.96ZM6.07989 8.96H7.67989V10.56H6.07989V8.96ZM8.31989 8.96H9.91989V10.56H8.31989V8.96ZM10.5599 8.96H12.1599V10.56H10.5599V8.96ZM3.83989 11.2H5.43989V12.8H3.83989V11.2ZM6.07989 11.2H7.67989V12.8H6.07989V11.2ZM8.31989 11.2H9.91989V12.8H8.31989V11.2ZM10.5599 11.2H12.1599V12.8H10.5599V11.2Z" fill="#04A584"/>
            </g>
            <defs>
                <clipPath id="clip0_1941_364">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default DetailsCalendarIcon;