import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 23px 10px'
    },
    leftWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
    },
    mainTitle: {
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '48px',
    },
    actionsWrapper: {
        display: 'flex',
        gap: 18,
        alignItems: 'center',
    },
    chooseWrapper: {
        color: '#738094',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',

        '& span': {
            color: '#070B35',
            fontWeight: 600,
        },
    },
    checkboxWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
})