import styled from 'styled-components';
import Typography from '../../../common/typography';

export const PageDescriptionWrapper = styled.div`
  margin-top: 16px;
`

export const PageDescription = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #070B35;
  white-space: normal !important;
  
  span {
    color: #738094;
  }
  
   a {
     font-weight: 400;
     font-size: 14px;
     line-height: 16px;
     color: #04A584;
     margin-left: 10px;
     
     svg {
       width: 16px;
       margin-bottom: -5px;
     }
   }
  
  i {
    cursor: pointer;
    svg path {
      fill: #2082EA;
    }
  }
  
`

export const PageWrapper = styled.div`
  width: 1204px;
  margin: 27px auto 0;
`
export const PageContent = styled.div`
  margin-top: 24px;
`
export const TabsContentWrapper = styled.div`
  margin-top: 16px;
`