import React from 'react';

const LinkedinIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 2.66667H3.99999C3.26332 2.66667 2.66666 3.26334 2.66666 4.00001V16C2.66666 16.7367 3.26332 17.3333 3.99999 17.3333H16C16.7367 17.3333 17.3333 16.7367 17.3333 16V4.00001C17.3333 3.26334 16.7367 2.66667 16 2.66667ZM7.30266 14.6667H5.33599V8.33867H7.30266V14.6667ZM6.29932 7.43401C5.66532 7.43401 5.15266 6.92 5.15266 6.28734C5.15266 5.65467 5.66599 5.14134 6.29932 5.14134C6.93132 5.14134 7.44532 5.65534 7.44532 6.28734C7.44532 6.92 6.93132 7.43401 6.29932 7.43401ZM14.6693 14.6667H12.704V11.5893C12.704 10.8553 12.6907 9.91134 11.682 9.91134C10.6587 9.91134 10.5013 10.7107 10.5013 11.536V14.6667H8.53599V8.33867H10.4227V9.20334H10.4493C10.712 8.70601 11.3533 8.18134 12.31 8.18134C14.3013 8.18134 14.6693 9.49201 14.6693 11.196V14.6667Z" fill="#00C099"/>
        </svg>
    );
};

export default LinkedinIcon;