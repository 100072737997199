import React from 'react';

const ExternalLinkIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3204 1.99349C17.2956 1.99427 17.2708 1.99645 17.2462 2H13.3334C13.2451 1.99875 13.1574 2.01507 13.0754 2.04801C12.9934 2.08095 12.9188 2.12986 12.8559 2.19189C12.793 2.25392 12.743 2.32784 12.7089 2.40934C12.6748 2.49085 12.6573 2.57831 12.6573 2.66666C12.6573 2.75501 12.6748 2.84248 12.7089 2.92399C12.743 3.00549 12.793 3.07941 12.8559 3.14144C12.9188 3.20347 12.9934 3.25237 13.0754 3.28532C13.1574 3.31826 13.2451 3.33458 13.3334 3.33333H15.724L8.86206 10.1953C8.79808 10.2567 8.747 10.3303 8.7118 10.4117C8.67661 10.4932 8.65802 10.5808 8.65712 10.6695C8.65622 10.7582 8.67302 10.8462 8.70655 10.9283C8.74007 11.0104 8.78965 11.085 8.85237 11.1477C8.91509 11.2104 8.98969 11.26 9.07181 11.2935C9.15393 11.3271 9.24191 11.3439 9.3306 11.343C9.4193 11.3421 9.50692 11.3235 9.58834 11.2883C9.66976 11.2531 9.74334 11.202 9.80477 11.138L16.6667 4.27604V6.66667C16.6655 6.75501 16.6818 6.84271 16.7148 6.92469C16.7477 7.00667 16.7966 7.08129 16.8586 7.1442C16.9207 7.20711 16.9946 7.25707 17.0761 7.29117C17.1576 7.32527 17.2451 7.34283 17.3334 7.34283C17.4218 7.34283 17.5092 7.32527 17.5907 7.29117C17.6722 7.25707 17.7462 7.20711 17.8082 7.1442C17.8702 7.08129 17.9191 7.00667 17.9521 6.92469C17.985 6.84271 18.0013 6.75501 18.0001 6.66667V2.7513C18.0134 2.65532 18.0056 2.55759 17.9772 2.46493C17.9489 2.37227 17.9007 2.2869 17.836 2.21477C17.7713 2.14264 17.6916 2.08547 17.6026 2.04726C17.5136 2.00904 17.4173 1.99069 17.3204 1.99349ZM4.00008 4.66666C3.27137 4.66666 2.66675 5.27129 2.66675 6V16C2.66675 16.7287 3.27137 17.3333 4.00008 17.3333H14.0001C14.7288 17.3333 15.3334 16.7287 15.3334 16V9.33333V7.61458L14.0001 8.94792V10.6667V16H4.00008V6H9.33342H10.6667H11.0522L12.3855 4.66666H10.6667H9.33342H4.00008Z" fill="#00C099"/>
        </svg>
    );
};

export default ExternalLinkIcon;