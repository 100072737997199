import React from 'react';

const CloseIcon = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 11.5L6.25 6.25M1 1L6.25 6.25M6.25 6.25L1 11.5M6.25 6.25L11.5 1" stroke="#E42736" strokeWidth="2"/>
        </svg>
    );
};

export default CloseIcon;