import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    padding: 25px;
`

export const CollectionsWrapper = styled.div`
    margin-top: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
`

export const CollectionsHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

export const EmptyList = styled.div`
    margin-top: 12px;
    font-size: 18px;
`