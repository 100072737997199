import React, {FC} from 'react';
import { IconInterface } from './IconInterface';

const PinIcon: FC<IconInterface> = ({className, fill = 'black'}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M11.9938 1.99347C11.8612 1.9935 11.7316 2.03309 11.6216 2.10717C11.5116 2.18124 11.4262 2.28644 11.3763 2.40932C11.3264 2.53219 11.3142 2.66716 11.3414 2.79696C11.3686 2.92676 11.4339 3.0455 11.529 3.138L11.5641 3.17316C10.2707 4.7994 8.66699 7.99998 8.66699 7.99998L6.75423 8.47784L6.47168 8.19529C6.40955 8.13143 6.33526 8.08067 6.25318 8.046C6.1711 8.01134 6.08291 7.99347 5.99382 7.99347C5.86119 7.9935 5.73159 8.03309 5.62159 8.10717C5.51158 8.18124 5.42617 8.28644 5.37626 8.40932C5.32636 8.53219 5.31423 8.66716 5.34143 8.79696C5.36864 8.92676 5.43393 9.0455 5.52897 9.138L10.8623 14.4713C10.9237 14.5353 10.9973 14.5864 11.0787 14.6216C11.1602 14.6568 11.2478 14.6754 11.3365 14.6763C11.4252 14.6772 11.5131 14.6604 11.5953 14.6268C11.6774 14.5933 11.752 14.5437 11.8147 14.481C11.8774 14.4183 11.927 14.3437 11.9605 14.2616C11.9941 14.1795 12.0109 14.0915 12.01 14.0028C12.0091 13.9141 11.9905 13.8265 11.9553 13.7451C11.9201 13.6636 11.869 13.5901 11.805 13.5286L11.5225 13.2461L12.0003 11.3333C12.0003 11.3333 15.2009 9.72959 16.8271 8.43618L16.8623 8.47133C16.9237 8.53532 16.9973 8.5864 17.0787 8.62159C17.1602 8.65678 17.2478 8.67537 17.3365 8.67627C17.4252 8.67718 17.5131 8.66037 17.5953 8.62685C17.6774 8.59332 17.752 8.54375 17.8147 8.48103C17.8774 8.41831 17.927 8.3437 17.9605 8.26159C17.9941 8.17947 18.0109 8.09148 18.01 8.00279C18.0091 7.9141 17.9905 7.82647 17.9553 7.74505C17.9201 7.66364 17.869 7.59006 17.805 7.52863L12.4717 2.19529C12.4096 2.13143 12.3353 2.08067 12.2532 2.046C12.1711 2.01134 12.0829 1.99347 11.9938 1.99347ZM6.8623 12.1953L2.86882 16.1888L2.8623 16.1953C2.73727 16.3203 2.66702 16.4898 2.66699 16.6666C2.66699 16.8435 2.73723 17.013 2.86225 17.1381C2.98728 17.2631 3.15685 17.3333 3.33366 17.3333C3.51046 17.3333 3.68001 17.263 3.80501 17.138L7.80501 13.138L6.8623 12.1953Z" fill={fill}/>
        </svg>
    );
};

export default PinIcon;