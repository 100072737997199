import React, {FC} from 'react';
import { IconInterface } from './IconInterface';

const TwitterIcon: FC<IconInterface> = ({className, fill = 'black'}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M18.6666 4.62467C18.0286 4.908 17.3433 5.09867 16.624 5.18467C17.3586 4.74467 17.922 4.048 18.1873 3.21733C17.5006 3.62467 16.7393 3.92067 15.9286 4.08067C15.28 3.38933 14.356 2.95733 13.3333 2.95733C11.3693 2.95733 9.77731 4.55 9.77731 6.51333C9.77731 6.792 9.80931 7.064 9.86931 7.32333C6.91398 7.17533 4.29398 5.75933 2.53931 3.60733C2.23397 4.13267 2.05864 4.74333 2.05864 5.396C2.05864 6.62933 2.68597 7.718 3.63998 8.35533C3.05731 8.33667 2.50864 8.17667 2.02931 7.91067C2.02931 7.926 2.02931 7.94 2.02931 7.95533C2.02931 9.67867 3.25464 11.116 4.88197 11.442C4.58397 11.5233 4.26931 11.5667 3.94464 11.5667C3.71597 11.5667 3.49264 11.544 3.27597 11.5033C3.72864 12.916 5.04198 13.9447 6.59798 13.9733C5.38131 14.9273 3.84798 15.496 2.18131 15.496C1.89464 15.496 1.61131 15.4793 1.33264 15.446C2.90664 16.4547 4.77531 17.0433 6.78398 17.0433C13.3253 17.0433 16.9013 11.6247 16.9013 6.92533C16.9013 6.77133 16.898 6.618 16.8913 6.46533C17.5866 5.96333 18.19 5.33733 18.6666 4.62467Z" fill={fill}/>
        </svg>
    );
};

export default TwitterIcon;