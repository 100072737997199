import { useEffect, useState } from "react"
import { FeatureItem, IInfo } from "../../types/global_types"
import CardsList from "./cards/list/CardsList"
import ProgressList from "./progress"
import Button from "../../common/button"
import updateInfo from "../../services/info/updateInfo"
import getInfo from "../../services/info/getInfo"
import Loader from "../../common/loader"
import inputsHandler from "../../utils/inputsHandler"
import TextEditor from "../../common/text_editor/TextEditor"
import InfoItem from "./InfoItem"
import { EditStateWrapper } from "../person_page_layout/styles"
import { Layout, CardsLayout, ProgressLayout, SaveWrapper, InfoItemsWrapper } from './styles';
import TextModal from "../../common/text_modal/TextModal"
import CreateButton from "../../common/button/create_button"

const InfoLayout = () => {
    const [isAboutModal,setIsAboutModal] = useState<boolean>(false)
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState<IInfo>({
      partners:[],
      progress:[],
      portfolio:[],
      aboutUsText:'',
      headerText:'',
      featuresItems:[],
      whyFomo:[]
    })

    const cardsHandler = (value:any,key:string) : void => {
      setData((prev:IInfo) => {
        return ({
          ...prev,
          [key]:value
        })
      })
    }

    const progressHandler = (value:any) : void => {
      setData((prev:IInfo) => {
        return ({
          ...prev,
          progress:value
        })
      })
    }

    const confirmUpdate = async () => {
      setLoading(true)
      const formData = new FormData()
      
      for (let i = 0; i < data.partners.length; i++) {
        const partner = data.partners[i];
        
        partner.img && typeof partner.img !== 'string' && formData.append(`partner_${i}`,partner.img)
      }

      for (let i = 0; i < data.portfolio.length; i++) {
        const portfolio = data.portfolio[i];
        
        portfolio.img && typeof portfolio.img !== 'string' && formData.append(`portfolio_${i}`,portfolio.img)
      }

      formData.append('portfolio',JSON.stringify(data.portfolio))
      formData.append('partners',JSON.stringify(data.partners))
      formData.append('progress',JSON.stringify(data.progress))
      formData.append('aboutUsText',data.aboutUsText)
      formData.append('headerText',data.headerText)
      formData.append('featuresItems',JSON.stringify(data.featuresItems))
      formData.append('whyFomo',JSON.stringify(data.whyFomo))
      
      const result = await updateInfo(data._id || 'none',formData)

      setLoading(false)
    }

    const infoItemsHandler = (name:'featuresItems' | 'whyFomo',values:FeatureItem,id:number) => {
      setData((prev:IInfo) => {
        return ({
          ...prev,
          [name]:prev[name].map((item:FeatureItem,i:number) => {
            if(i === id){
              return values
            }

            return item
          })
        })
      })
    }

    const addInfoItem = (name:'featuresItems' | 'whyFomo') => {
      setData((prev:IInfo) => {
        return ({
          ...prev,
          [name]:[...prev[name],{title:'',text:''}]
        })
      })
    }

    const removeInfoItem = (name:'featuresItems' | 'whyFomo',id:number) => {
      setData((prev:IInfo) => {
        return ({
          ...prev,
          [name]:prev[name].filter((item:FeatureItem,i:number) => {
            return i !== id
          })
        })
      })
    }

    useEffect(() => {
      getInfo().then(({success,data}) => {
        if(success){
          setData(data)
        }
      })
    },[])

    if(loading) return <Loader/>
    
  return (
    <>
    <Layout>
        <SaveWrapper>
          <Button
          onClick={confirmUpdate}
          type={'bordered'}
          >
            Save
          </Button>
        </SaveWrapper>

        <CardsLayout>
          <h2>
            Header text
          </h2>
          <EditStateWrapper>
            <textarea
            value={data.headerText}
            onChange={(e:any) => inputsHandler('headerText',e.target.value,setData)}
            />
          </EditStateWrapper>
        </CardsLayout>

        <CardsLayout>
          <h2>
            About us
          </h2>
          <Button
          type={'bordered'}
          onClick={() => setIsAboutModal(true)}
          >
            Edit text
          </Button>
          {/* <EditStateWrapper>
            <TextEditor
            value={data.aboutUsText}
            name={'aboutUsText'}
            onChange={(value:string,name:string) => inputsHandler(name,value,setData)}
            />
          </EditStateWrapper> */}
        </CardsLayout>

        <CardsLayout>
          <h2>
            Featues
          </h2>
          <InfoItemsWrapper>
            {
              data?.featuresItems?.map((item:FeatureItem,id:number) => {
                return (
                  <InfoItem
                  key={id}
                  values={item}
                  onChange={(values:FeatureItem) => infoItemsHandler('featuresItems',values,id)}
                  removeFeatuesItem={() => removeInfoItem('featuresItems',id)}
                  />
                )
              })
            }
            <CreateButton
            width={'300px'}
            height={'300px'}
            onClick={() => addInfoItem('featuresItems')}
            />
          </InfoItemsWrapper>
        </CardsLayout>

        <CardsLayout>
          <h2>
            Why FOMO
          </h2>
          <InfoItemsWrapper>
            {
              data?.whyFomo?.map((item:FeatureItem,id:number) => {
                return (
                  <InfoItem
                  key={id}
                  values={item}
                  onChange={(values:FeatureItem) => infoItemsHandler('whyFomo',values,id)}
                  removeFeatuesItem={() => removeInfoItem('whyFomo',id)}
                  />
                )
              })
            }
            <CreateButton
            width={'300px'}
            height={'300px'}
            onClick={() => addInfoItem('whyFomo')}
            />
          </InfoItemsWrapper>
        </CardsLayout>

        <CardsLayout>
            <h2>
                Portfolio
            </h2>
            <CardsList
            cardsKey={'portfolio'}
            cardsHandler={cardsHandler}
            items={data.portfolio}
            />
        </CardsLayout>

        <CardsLayout>
            <h2>
                Partners
            </h2>
            <CardsList
            cardsKey={'partners'}
            cardsHandler={cardsHandler}
            items={data.partners}
            />
        </CardsLayout>

        <ProgressLayout>
            <h2>
                Progress
            </h2>
            <ProgressList
            items={data.progress}
            progressHandler={progressHandler}
            />
        </ProgressLayout>
    </Layout>
    {
      isAboutModal
      ?
      <TextModal
      onClick={() => setIsAboutModal(false)}
      onClose={() => setIsAboutModal(false)}
      title="About us"
      value={data.aboutUsText}
      onChange={(value:string,name:string) => inputsHandler('aboutUsText',value,setData)}
      />
      :
      <></>
    }
    </>
  )
}

export default InfoLayout
