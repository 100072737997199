import React from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import Header from '../../components/layouts/earlyland_layouts/promohub_layout/header';
import ProjectTable from '../../components/layouts/earlyland_layouts/promohub_layout/projects_table';
import Description from '../../components/layouts/earlyland_layouts/promohub_layout/description';

const PromohubPage = () => {
    return (
        <Layout>
            <Header />
            <ProjectTable />
            <Description />
        </Layout>
    );
};

export default PromohubPage;