import {FC,useState} from 'react'
import {useStyles} from './styles';
import avatarImage from '../../../../../assets/img/avatar.png'
import Button from '../../../../common/button';
import Checkbox from '../../../../common/checkbox';
import { IAction } from '../../../../types/global_types';
import loader from '../../../../services/loader';
import parseDate from '../../../../utils/parseDate';
import { useSelector } from 'react-redux';
import getUserId from '../../../../utils/getUserId';
import getConfirmType from '../../../../utils/getConfirmType';
import updateAction from '../../../../services/confirm/updateAction';
import Loader from '../../../../common/loader';
import reloadWindow from '../../../../utils/reloadWindow';

interface IProps {
    action:IAction
    selectAction:(selectedAction:IAction) => void
    selectActionToMessage:(userId:string) => void
}

export interface IAdmin {
    role:string
}

const TableRow : FC<IProps>= ({action,selectAction,selectActionToMessage}) => {
    const {
        wrapper,
        rowWrapper,
        pointsWrapper,
        statusWrapper,
        walletWrapper,
        userWrapper,
        checkboxWrapper,
        actionsWrapper,
        redAction,
        projectTitle,
    } = useStyles()
    const [loading,setLoading] = useState(false)
    const user : IAdmin = useSelector((state:any) => state.auth)

    const actionHandler = async (type:'confirm' | 'reject') => {
        setLoading(true)

        const path = `actions/${user.role}/${type}`

        await updateAction(path,action._id)

        setLoading(false)
        reloadWindow()
    }

    if(loading){
        return <Loader/>
    }
 
    return (
        <div className={wrapper}>
            <div className={`container ${rowWrapper}`}>
                <div className={checkboxWrapper}>
                    <Checkbox
                        onChange={() => selectAction(action)}
                        active={action.selected ? action.selected : false}
                    />
                </div>
                <div className={userWrapper}>
                    {
                        action.userData?.photo || action.userData?.twitterData?.photo
                        ?
                        <img 
                        src={
                            action.userData?.photo
                            ?
                            loader(action.userData?.photo)
                            :
                            action.userData?.twitterData?.photo
                        } 
                        alt={action.userData.email}/>
                        :
                        <img src={avatarImage} alt={action.userData.email}/>
                    }
                    <p>{action.userData?.username || action.userData?.email}</p>
                </div>
                <div
                    className={walletWrapper}
                >
                    {
                        action.categoryData?.logo
                        &&
                        <img src={loader(action.categoryData?.logo)} alt={action.categoryData?.name || action.categoryData?.title}/>
                    }
                    {
                        action.categoryData?.image
                        &&
                        <img src={loader(action.categoryData.image)} alt={action.name || action.title}/>
                    }
                    <div>
                        <div className={projectTitle}>
                            <div>{action?.categoryData?.name || action?.categoryData?.title}</div>
                            <span>{action?.categoryData?.fullness}</span>
                        </div>
                        <div>
                            {action?.categoryData?.type || action?.categoryData?.niche}
                        </div>
                    </div>
                </div>
                <div className={statusWrapper}>
                    {action.type}
                </div>
                <div className={pointsWrapper}>
                    {parseDate(action.date,1)}
                </div>

                <div className={actionsWrapper}>
                    <Button
                        onClick={() => actionHandler('confirm')}
                        type='bordered'
                    >
                        Accept
                    </Button>
                    <Button
                        onClick={() => actionHandler('reject')}
                        type='bordered'
                        className={'decline-btn'}
                    >
                        Decline
                    </Button>
                    <Button
                    // @ts-ignore
                        onClick={() => selectActionToMessage(String(action.user))}
                        type='bordered'
                        className={'blue-btn'}
                    >
                        Send message
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default TableRow;