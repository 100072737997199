import {useState,useEffect} from 'react';
import Button from '../../../../common/button';
import Filter from '../../../../common/filter';
import SortBy from '../../../../common/sort_by';
import SearchBar from '../../../../common/search';
import {useStyles} from './styles';
import useFetch from '../../../../hooks/useFetch';
import Loader from '../../../../common/loader';
import { IProject } from '../../../../hooks/useCreateProject';
import CreatingProjectModal from '../../modals/creating_project';


const Header = () => {
    const {
        wrapper,
        leftWrapper,
        mainTitle,
    } = useStyles()
    const [isAddInvestorsModal,setIsAddInvestorsModal] = useState<boolean>(false)
    const [createModal, setCreateModal] = useState<boolean>(false)

    return (
        <div className={wrapper}>
            <div className={leftWrapper}>
                <h1 className={mainTitle}>
                    Projects list
                </h1>
                <SearchBar/>
                <Filter />
                <SortBy />
            </div>
            <Button type='bordered' onClick={() => setCreateModal(true)}>
                Create project
            </Button>
            {
                createModal
                ?
                <CreatingProjectModal
                isAddInvestorsModal={isAddInvestorsModal}
                hideModal={() => setIsAddInvestorsModal(true)}
                onClose={() => setCreateModal(false)}
                backToCreatingModal={() => {
                    setIsAddInvestorsModal(false)
                    setCreateModal(true)
                }}
                />
                :
                <></>
            }
        </div>
    );
};

export default Header;