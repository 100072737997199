import React from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import TasksLayout from '../../components/layouts/tasks_layout';

const TasksPage = () => {
  return (
    <Layout>
      <TasksLayout page={'tasks'}/>
    </Layout>
  )
}

export default TasksPage
