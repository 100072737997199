import { FC } from "react"
import InputWithLabel from "../../../common/components_for_modals/input_with_label"
import { EditStateWrapper } from "../../person_page_layout/styles"
import { FeatureItem } from "../../../types/global_types"
import { InfoItemWrapper } from "./styles"
import { DeleteBtnWrapper } from "../cards/create/styles"
import Button from "../../../common/button"
import DeleteIcon from "../../../common/Icons/delete_icon"

interface IProps {
    values:FeatureItem
    onChange:(values:FeatureItem) => void
    removeFeatuesItem:() => void
}

const InfoItem : FC<IProps> = ({values,onChange,removeFeatuesItem}) => {
  return (
    <InfoItemWrapper>
        <DeleteBtnWrapper>
            <Button
          onClick={removeFeatuesItem}
          >
            <DeleteIcon/>
          </Button>
        </DeleteBtnWrapper>
        <InputWithLabel
        label="Title"
        value={values.title}
        name={'title'}
        onChange={(value:string) => onChange({...values,title:value})}
        />
                    <span>
                Text
            </span>
        <EditStateWrapper>

            <textarea
            value={values.text}
            onChange={(e:any) => onChange({...values,text:e.target.value})}
            />
        </EditStateWrapper>
    </InfoItemWrapper>
  )
}

export default InfoItem
