import Marketplace from '../../../components/layouts/nfts_layouts/marketplace';
import Layout from '../../../components/layouts/main_layout/layout';

const MarketplacePage = () => {
    return (
        <Layout>
            <Marketplace/>
        </Layout>
    );
};

export default MarketplacePage;