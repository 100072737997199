import React from 'react';
import {Switch, useRouteMatch} from 'react-router';
import {Route} from 'react-router-dom';
import Projects from './Projects';
import Accelerator from './Accelerator';
import Calendar from './Calendar';
import News from './News';
import NewsPage from './News/NewsPage';
import Market from './Market';
import NotFoundPage from '../NotFound';
import AcceleratorProjectPage from './AcceleratorProject';
import Banner from './Banner';

const ProjectsPage = () => {
    const {path} = useRouteMatch()

    return (
        <>
            <Switch>
                <Route exact path={path} component={Projects} />
                <Route path={`${path}/project/:id`} component={AcceleratorProjectPage} />
                <Route path={`${path}/accelerator/:id`} component={AcceleratorProjectPage} />
                <Route path={`${path}/accelerator`} component={Accelerator} />
                <Route path={`${path}/calendar`} component={Calendar} />
                <Route path={`${path}/news/:id`} component={NewsPage} />
                <Route path={`${path}/news`} component={News} />
                <Route path={`${path}/market`} component={Market} />
                <Route path={`${path}/banner`} component={Banner} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </>
    );
};

export default ProjectsPage;