import React, {useState} from 'react';
import Button from '../../../common/button';
import SearchIcon from '../../../common/Icons/search_icon';
import Filter from '../../../common/filter';
import SortBy from '../../../common/sort_by';
import {useStyles} from './styles';
import AddProject from '../../gemslab_layouts/modals/add_project';

const Header = () => {
    const {
        wrapper,
        leftWrapper,
        mainTitle,
        searchWrapper,
        searchInput,
    } = useStyles()

    const [addModal, setAddModal] = useState(false)

    return (
        <div className={wrapper}>
            <div className={leftWrapper}>
                <h1 className={mainTitle}>
                    Info
                </h1>
            </div>
        </div>
    );
};

export default Header;