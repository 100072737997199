import React from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import ProjectLayout from '../../components/layouts/earlyland_layouts/project_layout';

const ProjectPage = () => {
    return (
        <Layout>
            <ProjectLayout />
        </Layout>
    );
};

export default ProjectPage;