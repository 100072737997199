import styled from 'styled-components';
import Typography from '../../common/typography';

export const PageTitle = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
`
export const PageWrapper = styled.div`
  width: 1302px;
  margin: 19px auto;
  
  .table-wrapper {
    width: 100% !important;
  }

  @media(max-width: 1302px) {
    width: 100%;
    padding: 0 16px;
    margin-top: 14px;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 0 23px;
`

export const LeftHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const GridButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #070B35;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  
  @media(max-width: 1024px) {
    justify-content: center;
  }
`

export const CalendarDay = styled.div`
  max-width: 179px !important;
  width:100%;
  height: 179px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: relative;
  padding: 9px !important;
  border: 1px solid rgba(83, 98, 124, 0.07);
  box-shadow: 4px 4px 0 #EEEEEE;
  border-radius: 8px;
  
  span {
    color: rgba(69, 64, 99, 0.5);
  }
`

export const DayHeader = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  gap: 4px;
  
  @media(max-width: 1024px) {
    span {
      display: none;
    }
  }
`

export const AddEventButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 5px;
  
  svg {
    transform: rotate(45deg);
  }
`

export const ShowAllButton = styled.button`
  position: absolute;
  bottom: 12px;
  border: none;
  background: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #04A584;
`

export const EventWrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 2px;
  transition: all 0.3s ease;
  padding: 6px 10px;
  border-radius: 2px;
  background: transparent;
  border: none;
  width: 100%;
  &:hover{
    opacity: 0.9;
    background: #8080802f;
  }
  &:active{
    opacity: 0.7;
    background: #8080802f;
  }
`

export const EventUser = styled.img`
  width: 12px;
  height: 12px;
  border-radius: 100px;
`

export const EventTitle = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #070B35;
  width: 104px;
`

export const EventDescription = styled(Typography)`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: rgba(69, 64, 99, 0.5);
  width: 104px;
`

export const EventTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #738094;
  flex-direction: column;

  span {
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: rgba(69, 64, 99, 0.5);
  }
`

export const MobileWeekDayName = styled.i`
  display: none;
  
  @media(max-width: 1024px) {
    display: block;
    color: rgba(69, 64, 99, 0.5);
  }
`

export const ProjectWrapper = styled.div`
  display:flex;
  align-items: center;
  flex-direction: column;
`

export const EventItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  max-width: 75px;
`