export const NavigationLinksList = [
    {
        title:'Banners',
        link:'/banners',
        isDropdown:false,
        links:[]
    },
    {
        title:'Info',
        link:'/info',
        isDropdown:false,
        links:[]
    },
    {
        title:'Support',
        link:'/support',
        isDropdown:false,
        links:[]
    },
    {
        title:'Main',
        link:'/main',
        isDropdown:false,
        links:[]
    },
    {
        title: 'Projects',
        link: '/projects',
        isDropdown: true,
        links: [
            {
                title: 'Projects list',
                link: '',
            },
            {
                title: 'Funds',
                link: '/funds',
            },
            {
                title: 'Persons',
                link: '/persons',
            },
            {
                title: 'OTC',
                link: '/otc',
            },
            {
                title: 'Calendar',
                link: '/calendar',
            },

            {
                title: 'News',
                link: '/news',
            },
        ],
    },
    {
        title: 'Nfts',
        link: '/nfts',
        isDropdown: true,
        links: [
            // {
            //     title: 'Projects list',
            //     link: '',
            // },
            {
                title: 'Calendar',
                link: '/calendar',
            },
            {
                title: 'News',
                link: '/news',
            },
            {
                title: 'Marketplace',
                link: '/marketplace',
            }
        ],
    },
    {
        title: 'EarlyLand',
        link: '/early_land',
        isDropdown: true,
        links: [
            {
                title: 'Recommendation',
                link: '',
            },
            {
                title: 'Promohub',
                link: '/promo_hub',
            },

            {
                title: 'Calendar',
                link: '/calendar',
            },

            {
                title: 'News',
                link: '/news',
            },
            {
                title: 'Tasks',
                link: '/tasks',
            },
        ],
    },
    {
        title: 'GemsLab',
        link: '/gems_lab',
        isDropdown: true,
        links: [
            {
                title: 'Projects list',
                link: '',
            },
            {
                title: 'Market',
                link: '/market',
            },
            {
                title: 'Accelerator',
                link: '/accelerator',
            },

            {
                title: 'Calendar',
                link: '/calendar',
            },
            {
                title: 'Banner',
                link: '/banner',
            },
        ],
    },
    {
        title: 'Users list',
        link: '/users_list',
        isDropdown: true,
        links: [
            {
                title: 'Users list',
                link: '',
            },
            {
                title: 'OTC Deals',
                link: '/otc'
            }
        ]
    },
    {
        title: 'Statistics',
        link: '/statistics',
    },
    {
        title: 'Confirming',
        link: '/confirming',
    },
    {
        title: 'Settings',
        link: '/settings',
    },
]