import { FC } from "react"
import ProgressColumn from "./column"
import CreateButton from "../../../common/button/create_button"
import { ProgressWrapper,ProgressColumnWraper } from "./styles"

interface IProps {
    items:Array<any>
    progressHandler: (value:any) => void 
}

const ProgressList : FC<IProps> = ({items,progressHandler}) => {

    const columnInputHandler = (value:any,columnId:number) : void => {
        const updatedItems : Array<any> = items.map((item:any,itemIndex:number) => {
            if(itemIndex === columnId){
                return value
            }   

            return item
        })
    
        progressHandler(updatedItems)
    }

    const addColumn = () : void => {
        progressHandler([...items,[]])
    }

    const removeColumn = (columnId:number) : void => {
        const updatedItems : Array<any> = items.filter((item:any,itemIndex:number) => itemIndex !== columnId)

        progressHandler(updatedItems)
    }
   
  return (
    <ProgressWrapper>
        {
            items.map((column:any,index:number) => {
                return (
                    <ProgressColumn
                    key={index}
                    column={column}
                    columnId={index}
                    columnHandler={columnInputHandler}
                    removeColumn={removeColumn}
                    />
                )
            })
        }
        <CreateButton
        width={'250px'}
        height={'250px'}
        onClick={addColumn}
        />
    </ProgressWrapper>
  )
}

export default ProgressList
