import React from 'react';
import Layout from '../../../components/layouts/main_layout/layout';
import ProjectPageLayout from '../../../components/layouts/projects_layouts/project_layout';

const ProjectPage = () => {
    return (
        <Layout>
            <ProjectPageLayout />
        </Layout>
    );
};

export default ProjectPage;