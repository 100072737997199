import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        background: '#F8F8F9',
    },
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 23px',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '14px',
        color: '#738094',
    },
    projectsCell: {
        width: 249,
    },
    statusCell: {
        width: 128,
    },
    investorsCell: {
        width: 245,
    },
    raisedCell: {
        width: 92,
    },
    fundingCell: {
        width: 110,
    },
    typeCell: {
        width: 240,
    },
    flagsCell: {
        width: 246,
    },
})