import {useState,useEffect} from 'react';
import { useHistory } from 'react-router';
import ProjectTable from '../../projects_layouts/projects_list_layout/project_table';
import CopyIcon from '../../../common/Icons/copy_icon';
import UserStatusDropdown from '../../../common/users_status_dropdown';
import Button from '../../../common/button';
import EditIcon from '../../../common/Icons/edit_icon';
import {USERS_STATUS_LIST} from '../../../../static_content/dropdowns_data';
import avatarImage from '../../../../assets/img/avatar.png'
import {useStyles} from './styles';
import DotsButtonWithDropdown from '../../../common/dots_button_with_dropdown';
import useFetch from '../../../hooks/useFetch';
import UpdateUserModal from '../modals/update_user_modal';
import Loader from '../../../common/loader';
import loader from '../../../services/loader';
import getAccessToken from '../../../utils/getAccessToken';
import changeStatus from '../../../services/user/changeStatus';
import SendMessageModal from '../../../common/sendMessageModal';
import sliceAddress from '../../../utils/sliceAddress';
import { IUser } from '../../../types/global_types';
import UserProjectTable from '../../projects_layouts/projects_list_layout/project_table copy';

const UserLayout = () => {
    const [isUpdateModal, setIsUpdateModal] = useState(false)

    const {
        headerWrapper,
        userDataWrapper,
        userName,
        userDescriptionWrapper,
        walletKeyWrapper,
        headerDataWrapper,
        dataTitle,
        dataName,
        actionsWrapper,
        socialItem,
        socialTitle,
        socialList,
        socialListItemValue
    } = useStyles()

    const [user,setUser] = useState<IUser>()
    const [userStatus, setUserStatus] = useState<USERS_STATUS_LIST>(user?.blocked ? USERS_STATUS_LIST.BLOCKED : USERS_STATUS_LIST.ACTIVE)
    const [isSendMessage,setIsSendMessage] = useState<boolean>(false)

    const userId = useHistory().location.pathname.split('/').pop()

    const {data,loading} = useFetch(`user/${userId}`,{headers:{'Authorization':`Bearer ${getAccessToken()}`}})

    const copyHandle = (value: string) => {
        navigator.clipboard.writeText(value)
    }

    const changeUserStatus = async (value : USERS_STATUS_LIST.ACTIVE | USERS_STATUS_LIST.BLOCKED) => {
        if(user?._id){
            setUserStatus(value)
            await changeStatus(`user`,user._id,value)
        }
    }

    useEffect(() => {
        data?.data && setUser(data.data)
    },[data])

    if(loading || !user){
        return <Loader/>
    }
    
    return (
        <>
        <div>
            <div className={headerWrapper}>
                <div className={userDataWrapper}>
                    {
                        user?.avatar || user?.twitterData?.photo
                        ?
                        <img src={
                            user?.avatar
                            ?
                            loader(user.avatar)
                            :
                            user?.twitterData?.photo
                        } alt={user.email}/>
                        :
                        <img src={avatarImage} alt=''/>
                    }
                    <div>
                        <div className={userName}>
                            {user?.username || user?.twitterData?.username}
                        </div>
                        <div className={userDescriptionWrapper}>
                            <div
                                className={walletKeyWrapper}
                                onClick={() => copyHandle(user.wallet || '')}
                            >
                                {sliceAddress(user.wallet) || '-'}
                                <CopyIcon />
                            </div>
                            <div>
                                <UserStatusDropdown
                                    onChange={(value) => changeUserStatus(value)}
                                    activeOption={userStatus}
                                />
                            </div>
                        </div>
                        <Button
                        type={"bordered"}
                        onClick={() => setIsSendMessage(true)}
                        >
                            Send message
                        </Button>
                    </div>
                </div>
                <div className={headerDataWrapper}>
                    <div>
                        <p className={dataName}>{user.staking || '-'}</p>
                        <p className={dataTitle}>Staking</p>
                    </div>
                    <div>
                        <p className={dataName}>{user?.tasks || '-'}</p>
                        <p className={dataTitle}>Tasks</p>
                    </div>
                    <div>
                        <p className={dataName}>{user?.projectLimit || '-'}</p>
                        <p className={dataTitle}>Projects limit</p>
                    </div>
                    <div>
                        <p className={dataName}>{user?.newsLimit || '-'}</p>
                        <p className={dataTitle}>News limit</p>
                    </div>
                    <div>
                        <p className={dataName}>{user.points || '0'}</p>
                        <p className={dataTitle}>Points</p>
                    </div>
                    <div>
                        <p className={dataName}>{user.email}</p>
                        <p className={dataTitle}>Email</p>
                    </div>
                    <div className={actionsWrapper}>
                        <Button
                            onClick={() => setIsUpdateModal(true)}
                            type='outlined'
                        >
                            <EditIcon />
                        </Button>
                        <DotsButtonWithDropdown items={[
                            {title: 'Give red status', onClick: () => console.log(1)},
                            {title: 'Delete', onClick: () => console.log(1)}
                        ]} />
                    </div>
                </div>
            </div>
            <div className={headerWrapper}>
                <div className={headerDataWrapper}>
                    <div className={socialItem}>
                        <p className={socialTitle}>Twitter</p>
                        <div className={socialList}>
                            <div>
                                <span className={dataTitle}>Name:</span>
                                <span className={socialListItemValue}>{user?.twitterData?.name || '-'}</span>
                            </div>
                            <div>
                                <span className={dataTitle}>Username:</span>
                                <span className={socialListItemValue}>{user?.twitterData?.username || '-'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={socialItem}>
                        <p className={socialTitle}>Discord</p>
                        <div className={socialList}>
                            <div>
                                <span className={dataTitle}>Name:</span>
                                <span className={socialListItemValue}>{user?.discordData?.name || '-'}</span>
                            </div>
                            <div>
                                <span className={dataTitle}>Username:</span>
                                <span className={socialListItemValue}>{user?.discordData?.username || '-'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={socialItem}>
                        <p className={socialTitle}>Telegram</p>
                        <div className={socialList}>
                            <div>
                                <span className={dataTitle}>Name:</span>
                                <span className={socialListItemValue}>{user?.telegramData?.name || '-'}</span>
                            </div>
                            <div>
                                <span className={dataTitle}>Username:</span>
                                <span className={socialListItemValue}>{user?.telegramData?.username || '-'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                user.projects?.length
                ?
                <UserProjectTable 
                projects={user.projects}
                />
                :
                <></>
            }
            {
            isUpdateModal && user 
            ?
            <UpdateUserModal
                user={user}
                onClose={() => setIsUpdateModal(false)}
            />
            :
            <></>
            }
        </div>
        <SendMessageModal
        userEmail={user.email}
        userChatId={user.telegramData?.telegramId}
        isVisible={isSendMessage}
        onClose={() => setIsSendMessage(false)}
        />
        </>
    );
};

export default UserLayout;