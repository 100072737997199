import React from 'react';

const RedFlag = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.54166 2.5C3.3759 2.50002 3.21694 2.56587 3.09973 2.68308C2.98253 2.80028 2.91667 2.95924 2.91666 3.125V17.7083C2.91549 17.7912 2.93079 17.8734 2.96167 17.9502C2.99255 18.0271 3.03841 18.097 3.09656 18.156C3.15471 18.215 3.22401 18.2618 3.30042 18.2938C3.37683 18.3258 3.45883 18.3422 3.54166 18.3422C3.62449 18.3422 3.70649 18.3258 3.7829 18.2938C3.85931 18.2618 3.92861 18.215 3.98676 18.156C4.04491 18.097 4.09076 18.0271 4.12164 17.9502C4.15253 17.8734 4.16783 17.7912 4.16666 17.7083V13.75H17.2917C17.4077 13.7499 17.5215 13.7176 17.6202 13.6565C17.7189 13.5955 17.7986 13.5081 17.8505 13.4043C17.9024 13.3005 17.9243 13.1843 17.9139 13.0687C17.9034 12.9531 17.861 12.8427 17.7913 12.7498L14.3229 8.125L17.7913 3.50016C17.861 3.40732 17.9034 3.29693 17.9139 3.18134C17.9243 3.06575 17.9024 2.94953 17.8505 2.84571C17.7986 2.74188 17.7189 2.65455 17.6202 2.59349C17.5215 2.53243 17.4077 2.50006 17.2917 2.5H3.54166Z" fill="#FF5858"/>
        </svg>
    );
};

export default RedFlag;