import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        width: 36,
        height: 36,

        '& svg': {
            fontSize: '40px !important'
        },

        '& .CircularProgressbar-text': {
            fontWeight: 800,
            fontSize: '40px',
            lineHeight: '17px',
            textAlign: 'center',
            color: '#04A584',
            paddingTop: '5px',
        }
    }
})