import getAccessToken from "../../utils/getAccessToken"
import { configureUrl } from "../config"
import { IReturnData } from "../types"

export interface Social {
    email:string
    twitter:string
    telegramRu:string
    telegramEn:string
    youtube:string
}

export interface Legal {
    policy:string
    terms:string
    disclaimer:string
}

export interface IFooter {
    social:Social
    legal:Legal
}

export default async (footer:IFooter) : Promise<IReturnData> => {
    try{
        const token : string = getAccessToken()

        if(!token){
            throw new Error('Not auth')
        }

        const responce = await fetch(configureUrl(`layout`),{
            method:'POST',
            headers:{
                'Authorization': `Bearer ${token}`,
                'Content-Type':'application/json'
            },
            credentials:'include',
            body:JSON.stringify(footer)
        });

        return {success:true,data:'Footer updated'}

    }catch(error){
        console.log(error)
        return {success:false,data:error}
    }
}