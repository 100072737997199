import styled from "styled-components";


export const ComparisonWrapper = styled.div`
    position: relative;

`

export const ComparisonAddBtn = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
`

export const ComparisonItems = styled.div`
    display: flex;
    flex-wrap: wrap;
`