import styled from 'styled-components';


export const CreateCardWrapper = styled.div`
    max-width: 300px;
    height: 300px;
    width:100%;
    position: relative;
    padding: 15px;
    padding-top: 40px;
    border-radius: 8px;
    border:1px solid #04A584;

    display:flex;
    flex-direction: column;
    gap: 16px;
    
    & img{
        max-width: 111px;
        height: 111px;
        object-fit: cover;
    }
`

export const DeleteBtnWrapper = styled.div`
    position:absolute;
    top: 12px;
    right: 12px;
`