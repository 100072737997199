import React from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import Header from '../../components/layouts/statistics_layout/header';
import UsersTable from '../../components/layouts/statistics_layout/users_table';

const StatisticsPage = () => {
    return (
        <Layout>
            <Header />
            <UsersTable />
        </Layout>
    );
};

export default StatisticsPage;