import { useState,useContext } from 'react';
import { useSelector } from 'react-redux';
import SearchIcon from '../../../common/Icons/search_icon';
import FilterByProps from '../../../common/filter_by_props';
import SortByProps from '../../../common/sort_by_props';
import Button from '../../../common/button';
import updateManyActions from '../../../services/confirm/updateManyActions';
import { IAction } from '../../../types/global_types';
import { IAdmin } from '../table/table_row';
import reloadWindow from '../../../utils/reloadWindow';
import Loader from '../../../common/loader';
import {useStyles} from './styles';
import { ConfirmingContext } from '../../../../pages/Confirming';

const Header = () => {
    const {
        wrapper,
        leftWrapper,
        mainTitle,
        searchWrapper,
        searchInput,
        actionsWrapper,
        chooseWrapper,
        declineButton,
    } = useStyles()
    const [loading,setLoading] = useState<boolean>(false)
    const user : IAdmin = useSelector((state:any) => state.auth)
    const {
        totalSelected,
        actions,
        searchAction,
        sortItems,
        filterItems,
        refetch,
        filters,
    } = useContext(ConfirmingContext)

    const confirmUpdateActions = async (type:'reject' | 'confirm') : Promise<void> => {
        setLoading(true)

        const actionIds : Array<string> 
        = 
        actions.filter((action:IAction) => action.selected).map((action:IAction) => action._id)

        const {success} = await updateManyActions(`actions/${user.role}/${type}`,actionIds)

        if(success && refetch) refetch()

        setLoading(false)
    } 

    if(loading) return <Loader/>

    return (
        <div className={wrapper}>
            <div className={leftWrapper}>
                <h1 className={mainTitle}>
                    Confirming
                </h1>
                <div className={searchWrapper}>
                    <SearchIcon />
                    <input
                    onChange={(e:any) => searchAction && searchAction(e.target.value || '')}
                    type="text"
                    placeholder='Search'
                    className={searchInput}
                    />
                </div>
                <FilterByProps 
                onChange={filterItems}
                options={filters}
                />
                <SortByProps 
                onChange={(value:string) => sortItems && sortItems(value)}
                />
            </div>
            <div className={actionsWrapper}>
                <p className={chooseWrapper}>
                    Choose: <span>{totalSelected}</span>
                </p>
                <Button
                    type='bordered'
                    onClick={() => confirmUpdateActions('confirm')}
                >
                    Accept
                </Button>
                <Button
                    type='bordered'
                    onClick={() => confirmUpdateActions('reject')}
                    className={'decline-btn'}
                >
                    Decline
                </Button>
            </div>
        </div>
    );
};

export default Header;