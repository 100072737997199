import React from 'react'
import Layout from '../../components/layouts/main_layout/layout'
import PageBanners from '../../components/common/banners'

const BannersPage = () => {
  return (
    <Layout>
      <PageBanners/>
    </Layout>
  )
}

export default BannersPage
