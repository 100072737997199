import { FC } from "react"
import { IInfoItem } from "../../../../types/global_types"
import InputWithLabel from "../../../../common/components_for_modals/input_with_label"
import FileInput from "../../../../common/file_input"
import Button from "../../../../common/button"
import DeleteIcon from "../../../../common/Icons/delete_icon"
import { CreateCardWrapper,DeleteBtnWrapper } from "./styles"

interface IProps extends IInfoItem {
    onChange:(value:any,name:string,cardIndex:number) => void
    removeCard: (id:number) => void
    cardIndex:number
}

const CreateCard : FC<IProps> = ({link,img,onChange,removeCard,cardIndex}) => {
  return (
    <CreateCardWrapper>
        <InputWithLabel
        label={'Link:'}
        name={'link'}
        value={link}
        onChange={(value:string,name:any) => onChange(value,String(name || ''),cardIndex)}
        placeholder={'https://example.com'}
        />
        <FileInput
        data={{image:img}}
        inputLabel={'File input'}
        inputsHandler={(file:any) => onChange(file,'img',cardIndex)}
        />
        <DeleteBtnWrapper>
          <Button
          onClick={() => removeCard(cardIndex)}
          >
            <DeleteIcon/>
          </Button>
        </DeleteBtnWrapper>
    </CreateCardWrapper>
  )
}

export default CreateCard
