import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    title: {
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '48px',
        marginBottom: 12,
        padding: '0 23px',
        marginTop: 16,
    }
})