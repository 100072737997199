import Typography from '../../../../common/typography';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  max-height: 500px;
  margin-top: 24px;
`

export const EventWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(83, 98, 124, 0.07);
  border-radius: 8px;
`

export const ProjectWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 27px;
`

export const ProjectTitle = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #070B35;
  width: 120px;
`

export const ProjectDescription = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #738094;
  width: 120px;
`

export const StatusWrapper = styled.div`
  width: 60px;
`

export const EventNameWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #070B35;
  width: 90px;
  margin-left: 35px;
`

export const DateWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #070B35;
  width: 147px;
  margin-right: 30px;
`

export const TimerWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 40px;
`

export const TimerItem = styled(Typography)`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #070B35;
  
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #738094;
  }
`

export const RatingWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #070B35;
  }
  
  svg {
    width: 24px;
    height: 24px;
    margin-top: -5px;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-left: 20px;
`