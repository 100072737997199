import React from 'react';

const VerticalDotsIcon = () => {
    return (
        <svg width="4" height="22" viewBox="0 0 4 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#738094"/>
            <circle cx="2" cy="11" r="2" fill="#738094"/>
            <circle cx="2" cy="20" r="2" fill="#738094"/>
        </svg>
    );
};

export default VerticalDotsIcon;