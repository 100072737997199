import styled from "styled-components";


export const InfoItemWrapper = styled.div`
    position: relative;
    min-width: 340px;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid gray;
    padding: 32px 8px 8px 8px;
    border-radius: 6px;

    span{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: rgb(115, 128, 148);
    }

    textarea{
        max-width: 320px;
        height: 200px;
    }
`