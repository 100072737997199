import React, { useState, useEffect, useRef, createContext } from 'react';
import Layout from '../../components/layouts/main_layout/layout';
import Header from '../../components/layouts/users_list_layout/users_list_otc/header';
import UsersTable from '../../components/layouts/users_list_layout/users_list_otc/users_table';
import { useQuery } from 'react-query';
import fetchDeals from '../../components/services/otc/fetchDeals';
import { IDeal } from '../../components/types/global_types';
import { statusesReverse } from '../../components/layouts/users_list_layout/users_list_otc/users_table/table_row';

export const OtcFilterContext = createContext({
    filters:{},
    onFiltersChange:(value:any) => {
        console.log(value)
    },
    searchValue:'',
    onSearchChange:(value:string) => {
        console.log(value)
    },
    sortValue:'',
    onSortChange:(value:string) => {
        console.log(value)
    },
})

export const buildQueryString = (
    filters: any, 
    sortField: string, 
    limit: number, 
    offset: number,
    isMyDeals?:boolean,
  ) => {
    const params: any = {
      limit,
      offset,
    };
  
    if(filters.serviceType){
      params.serviceType = filters.serviceType 
    }
    if(filters.userStatus) {
      params.userStatus = filters.userStatus
    }
    if(filters.movingTokens) {
      params.movingTokens = filters.movingTokens
    }
    if(filters.risk) {
      params.risk = filters.risk
    }
    if (filters.searchValue) {
      params.searchValue = filters.searchValue;
    }
    if (filters.startDate) {
      params.startDate = filters.startDate.toISOString();
    }
    if (filters.endDate) {
      params.endDate = filters.endDate.toISOString();
    }
    if (filters.priceEth) {
      params.minPriceEth = filters.priceEth[0];
      params.maxPriceEth = filters.priceEth[1];
    }
    if (filters.priceUsdc) {
      params.minPriceUsdc = filters.priceUsdc[0];
      params.maxPriceUsdc = filters.priceUsdc[1];
    }
    if (filters.amount) {
      params.minAmount = filters.amount[0];
      params.maxAmount = filters.amount[1];
    }
    if (filters.rating) {
      params.minRating = filters.rating[0];
      params.maxRating = filters.rating[1];
    }
    if (filters.tickers) {
      params.tickers = filters.tickers
    }
    if (sortField) {
      params.sortField = sortField;
    }
    if(isMyDeals) {
      params.userDeals = 'true'
    }
  
    if(filters.dealStatus?.length){
      params.dealStatus = filters.dealStatus.map((item:"Available" |  "Wait for confirm" | 'Started' | 'Funds reserved' | 'Ended') => {
        return statusesReverse[item]
      })
    }
  
    const queryString = new URLSearchParams(params).toString();
    return `?${queryString}`;
};

const UsersListOTCPage = () => {
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [deals, setDeals] = useState<IDeal[]>([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [filters,setFilters] = useState<any>()
    const [searchValue,setSearchValue] = useState<string>('')
    const [sortValue,setSortValue] = useState<string>('')
    const [queryString,setQueryString] = useState<string>('')

    const observer = useRef<IntersectionObserver | null>(null);
    const bottomRef = useRef<HTMLDivElement | null>(null); 

    const { refetch, isFetching } = useQuery(
        ['users-list', queryString],
        () => fetchDeals(queryString), 
        {
            onSuccess: (data) => {
                const newDeals = data?.data?.deals || [];
                setTotal(data?.data?.total || 0);
                setDeals(newDeals); 
            },
            refetchOnWindowFocus: false,
        }
    );

    const loadMoreDeals = async () => {
        if (loadingMore || total === deals.length) return; 
        setLoadingMore(true);
        setPage((prev) => prev + 1);
        setLoadingMore(false);
    };

    useEffect(() => {
        if (isFetching || loadingMore) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreDeals(); 
            }
        });

        if (bottomRef.current) observer.current.observe(bottomRef.current);
    }, [isFetching, loadingMore, deals]);

    useEffect(() => {
        const timer = setTimeout(() => {
          setQueryString(buildQueryString({ ...filters, searchValue }, sortValue, (page + 1) * 10, 0));
        }, 600); 
    
        return () => clearTimeout(timer); 
      }, [filters, searchValue, sortValue, page]);
      

    return (
        <OtcFilterContext.Provider
        value={{
            filters,
            onFiltersChange:(value:any) => {
                setFilters(value)
            },
            searchValue,
            onSearchChange:(value:any) => {
                setSearchValue(value)
            },
            sortValue,
            onSortChange:(value:any) => {
                setSortValue(value)
            },
        }}
        >
            <Layout>
                <Header/>
                <UsersTable 
                    deals={deals}
                    isFetching={isFetching}
                    total={total}
                    bottomRef={bottomRef}
                />
            </Layout>
        </OtcFilterContext.Provider>
    );
};

export default UsersListOTCPage;
