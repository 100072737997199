import { FC } from "react"
import Button from "../../../../common/button"
import InputWithLabel from "../../../../common/components_for_modals/input_with_label"
import DeleteIcon from "../../../../common/Icons/delete_icon"
import { 
    ColumnWrapper,
    ColumnTitle,
    RowItem,
    DeleteColumnWrapper,
    ColumnRows,
    SelectedIcon,
    StatusBtnWrapper,
    StatusListWrapper,
    StatusLabel
} from "./styles"
import { CheckIcon } from "../../../../../assets"

interface IProps {
    column:Array<any>
    columnId:number
    columnHandler: (column:any,columnIndex:number) => void
    removeColumn:(columnIndex:number) => void
}

const statusList = [
    {
        value:"done",
        color:'',
        btnText:'Done'
    },
    {
        value:"inwork",
        color:'',
        btnText:'In work'
    },
    {
        value:"upcoming",
        color:'',
        btnText:'Upcoming'
    },
]

const ProgressColumn : FC<IProps> = ({column,columnId,columnHandler,removeColumn}) => {

    const rowInputHandler = (value:any,name:string,rowId:number) => {
        const rowItem = column[rowId]

        columnHandler(column.map((item:any,id:number) => {
            if(id === rowId){
                return {...rowItem,[name]:value}
            }

            return item 
        }),columnId)
    }
    
    const addRow = () : void => {
        columnHandler([...column,{text:'',status:'upcoming'}],columnId)
    }

    const removeRow = (rowId:number) : void => {
        columnHandler(column.filter((row:any,id:number) => id !== rowId),columnId)
    }

  return (
    <ColumnWrapper>
        <DeleteColumnWrapper>
            <Button
            onClick={() => removeColumn(columnId)}
            >
                <DeleteIcon/>
            </Button>
        </DeleteColumnWrapper>
        <ColumnTitle>
        {`${(columnId + 1)}${(columnId + 1) === 1 ? 'st' : 'nd'}`} Stage
        </ColumnTitle>
        <ColumnRows>
            {
                column.map((row:any,index:number) => {
                    return (
                        <RowItem
                        key={index}
                        >
                            <InputWithLabel
                            placeholder="Development of webportal"
                            label="Text"
                            name={'text'}
                            value={row.text}
                            onChange={(value:any,name:any) => rowInputHandler(value,name,index)}
                            />
                            <StatusLabel>   
                                Status
                            </StatusLabel>
                            <StatusListWrapper>
                            {
                                statusList.map((item:{value:string,color:string,btnText:string}) => {
                                    return (
                                        <StatusBtnWrapper
                                        key={item.value}
                                        >
                                            {
                                                item.value === row.status 
                                                ?
                                                <SelectedIcon>
                                                <CheckIcon
                                                fill={'white'}
                                                />
                                                </SelectedIcon>
                                                :
                                                <></>
                                            }
                                            <Button
                                            className={`progress-${item.value}`}
                                            onClick={() => rowInputHandler(item.value,'status',index)}
                                            type={'bordered'}
                                            >
                                                {item.btnText}
                                            </Button>
                                        </StatusBtnWrapper>
                                    )
                                })
                            }
                            </StatusListWrapper>

                            <Button
                            className={'decline-btn'}
                            type={'bordered'}
                            onClick={() => removeRow(index)}
                            >
                                Remove
                            </Button>
                        </RowItem>
                    )
                })
            }</ColumnRows>
        <Button
        onClick={addRow}
        type={'bordered'}
        >
            Add row
        </Button>
    </ColumnWrapper>
  )
}

export default ProgressColumn
