import { FC, useState } from "react"
import Button from "../../../common/button"
import SendMessageModal from "../../../common/sendMessageModal"
import Modal from "../../../common/modal"
import { useStyles } from "./styles"

interface IProps {
    visible:boolean,
    modalHandler:any,
    supportItem:any
}

const AllSupportData : FC<IProps> = ({modalHandler,supportItem}) => {
    const {
        TextWrapper,
        ButtonWrapper
    } = useStyles()
    const [isSendMessage,setIsSendMessage] = useState<boolean>(false)
    
  return (
    isSendMessage
    ?
    <SendMessageModal
    userChatId={supportItem?.userData?.telegramData?.telegramId}
    userEmail={supportItem?.userData?.email}
    isVisible={isSendMessage}
    onClose={() => setIsSendMessage(false)}
    />
    :
    <Modal
    onClose={modalHandler}
    variant={"medium"}
    title={supportItem.theme}
    >
        <div
        className={TextWrapper}
        >
            {supportItem.message}
        </div>
        {
            supportItem?.userData?.telegramData?.telegramId && supportItem?.userData?.email
            ?
            <div className={ButtonWrapper}>
            <Button
            type={'lightFill'}
            onClick={() => setIsSendMessage(true)}
            >
                Send asnwer
            </Button>
            </div>
            :
            <></>
        }

    </Modal>
  )
}

export default AllSupportData
