import { useState, useEffect } from 'react';

declare global {
  interface Window {
    ethereum?: any;
  }
}

export function useConnectWallet () {
  const [address, setAddress] = useState<string>('');
  const [accounts, setAccounts] = useState<string[]>([]);
  
  const connectWallet = async () : Promise<boolean> => {
    try {
      if (window?.ethereum) {
        const ethereum = window.ethereum as any;

        await ethereum.request({ method: 'eth_requestAccounts' });

        setAddress(String(window?.ethereum?.selectedAddress).toLowerCase());
        
        ethereum.on('accountsChanged', (newAccounts: any) => {
          setAccounts(newAccounts);
        });
        
        ethereum.on('chainChanged', () => {
          window.location.reload();
        });

        return !!window?.ethereum?.selectedAddress;
      } else {
        alert('Please install MetaMask!');
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return { accounts:[address], connectWallet };
}