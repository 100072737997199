import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    graphicWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    graphicTopWrapper: {
        width: 320,
        background: 'white',
        padding: 16,
        borderRadius: 8,
        border: '1px solid rgba(83, 98, 124, 0.07)',
        boxShadow: '4px 4px 0px #EEEEEE',
        marginBottom: 16,
    },
    graphicTopTitle: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '21px',
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        marginBottom: 16,

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        }
    },
    graphicTopRow: {
        display: 'flex',
        gap: 54,
    },
    graphicTopCell: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,

        '& > div': {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
        },

        '& span': {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '19px',
        },
    },
    graphicBottomRow: {
        display: 'flex',
        justifyContent: 'space-between',

        '&:not(:first-child)': {
            paddingTop: 19,
        },
        '&:not(:last-child)': {
            borderBottom: '2px solid #F8F8F9',
            paddingBottom: 10,
        }
    },
    graphicBottomRowTitle: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        color: '#738094',

        '& svg': {
            width: 16,

            '& path': {
                fill: '#2082EA'
            },
        },

        '& + span': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#738094',
        }
    },
    graphicBottomRowValue: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '19px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        textAlign: 'right',

        '& span': {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '17px',
        }
    },
})