import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        background: 'none',
        border: 'none',
        display: 'flex',
        padding: '0 10px',
        justifyContent: 'center',
        alignItems: 'center',
    },
})