import { fetchData } from "../../hooks/useFetch"
import { IAction } from "../../types/global_types"
import getAccessToken from "../../utils/getAccessToken"

export const options = {
    method:'GET',
    headers:{
        'Authorization':`Bearer ${getAccessToken()}`
    }
}

export const getActionsData = async () : Promise<Array<IAction>> => {
    try{
        const role : string | null = localStorage.getItem('fomoRole')

        const {data} = await fetchData(`actions/${role}`,options)

        return data
    }catch(error){
        console.log(error)

        return []
    }
}
