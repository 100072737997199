/* eslint-disable */
import { ethers,Contract } from "ethers";
import { number_to_bigNumber } from "./initialSmartMain";

export const addressPool = '0x727a0A29C360CEdcEB9f1bd1Acc79E45bd561C79';
export const addressNft = '0x716746316Cb3Ba3e6aee4fB37356FE7dd9BeAe1b';

const getMainContract = () : any => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner()
    const abiPool = [
		{
			"inputs": [],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "_item_id",
					"type": "uint256"
				}
			],
			"name": "ItemCreated",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "_item_id",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "_price",
					"type": "uint256"
				}
			],
			"name": "Sold",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "Collections",
			"outputs": [
				{
					"internalType": "bool",
					"name": "",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "Creator",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "CreatorFee",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "Items",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "item_id",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "endTime",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "token_id",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "token_address",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "price",
					"type": "uint256"
				},
				{
					"internalType": "bool",
					"name": "Available",
					"type": "bool"
				},
				{
					"internalType": "address",
					"name": "seller",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "buyer",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "Items_usd",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "item_id",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "endTime",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "token_id",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "token_address",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "price",
					"type": "uint256"
				},
				{
					"internalType": "bool",
					"name": "Available",
					"type": "bool"
				},
				{
					"internalType": "address",
					"name": "seller",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "buyer",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"name": "Royalty",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_coll",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_new_fee",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_creator",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_crt_fee",
					"type": "uint256"
				}
			],
			"name": "add_collection",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_price",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_coll",
					"type": "address"
				}
			],
			"name": "calculateFee",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_price",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_coll",
					"type": "address"
				}
			],
			"name": "calculateTotalPrice",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_price",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_coll",
					"type": "address"
				}
			],
			"name": "calculate_creator_Fee",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_coll",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "_creator",
					"type": "address"
				}
			],
			"name": "change_creator",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_coll",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_new_fee",
					"type": "uint256"
				}
			],
			"name": "change_creator_fee",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_coll",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_new_fee",
					"type": "uint256"
				}
			],
			"name": "change_fee",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_endTime",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "_token_id",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_token_address",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_price",
					"type": "uint256"
				}
			],
			"name": "createItem",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_endTime",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "_token_id",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_token_address",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_price",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_adrby",
					"type": "address"
				}
			],
			"name": "createItem_for_sm",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_endTime",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "_token_id",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_token_address",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_price",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_adrby",
					"type": "address"
				}
			],
			"name": "createItem_for_sm_usd",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_endTime",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "_token_id",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "_token_address",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "_price",
					"type": "uint256"
				}
			],
			"name": "createItem_usd",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_coll",
					"type": "address"
				}
			],
			"name": "delete_collection",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "fee_price",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "get_all_Available_items",
			"outputs": [
				{
					"components": [
						{
							"internalType": "uint256",
							"name": "item_id",
							"type": "uint256"
						},
						{
							"internalType": "uint256",
							"name": "endTime",
							"type": "uint256"
						},
						{
							"internalType": "uint256",
							"name": "token_id",
							"type": "uint256"
						},
						{
							"internalType": "address",
							"name": "token_address",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "price",
							"type": "uint256"
						},
						{
							"internalType": "bool",
							"name": "Available",
							"type": "bool"
						},
						{
							"internalType": "address",
							"name": "seller",
							"type": "address"
						},
						{
							"internalType": "address",
							"name": "buyer",
							"type": "address"
						}
					],
					"internalType": "struct MShop.Item[]",
					"name": "",
					"type": "tuple[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "get_all_Available_items_usd",
			"outputs": [
				{
					"components": [
						{
							"internalType": "uint256",
							"name": "item_id",
							"type": "uint256"
						},
						{
							"internalType": "uint256",
							"name": "endTime",
							"type": "uint256"
						},
						{
							"internalType": "uint256",
							"name": "token_id",
							"type": "uint256"
						},
						{
							"internalType": "address",
							"name": "token_address",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "price",
							"type": "uint256"
						},
						{
							"internalType": "bool",
							"name": "Available",
							"type": "bool"
						},
						{
							"internalType": "address",
							"name": "seller",
							"type": "address"
						},
						{
							"internalType": "address",
							"name": "buyer",
							"type": "address"
						}
					],
					"internalType": "struct MShop.Item[]",
					"name": "",
					"type": "tuple[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_itemId",
					"type": "uint256"
				}
			],
			"name": "get_item_by_id",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_itemId",
					"type": "uint256"
				}
			],
			"name": "get_item_by_id_usd",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				},
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "item_id",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "item_id_usd",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "nft_count",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address payable",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_itemId",
					"type": "uint256"
				}
			],
			"name": "purchaseItem",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_itemId",
					"type": "uint256"
				}
			],
			"name": "purchaseItem_1",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_itemId",
					"type": "uint256"
				}
			],
			"name": "purchaseItem_2",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_itemId",
					"type": "uint256"
				}
			],
			"name": "purchaseItem_3",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_itemId",
					"type": "uint256"
				}
			],
			"name": "purchaseItem_usd",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "token",
			"outputs": [
				{
					"internalType": "contract IERC20",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "token_nft",
			"outputs": [
				{
					"internalType": "contract IERC721",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "total_volume_eth",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "total_volume_usd",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		}
	];
    const contractPool = new Contract(addressPool, abiPool, provider); 
    const daiContractWithSignerPool = contractPool.connect(signer);

    return {contractPool,daiContractWithSignerPool}
}

export async function addCollection(adr:string,fee:number,creator:string,creatorFee:number) : Promise<boolean> {  
    try {
        const {daiContractWithSignerPool} = getMainContract()

		const feeBigInt = number_to_bigNumber(fee,1)
		const creatorFeeBigInt = number_to_bigNumber(creatorFee,1)

        await daiContractWithSignerPool.add_collection(adr,feeBigInt,creator,creatorFeeBigInt);

        return true
      } catch (err) {
        console.info('err in transaction', err);

        return false
    }
}
  
export async function changeFee(adr:string,fee:number) : Promise<boolean> {  
    try {
		const feeBigInt = number_to_bigNumber(fee,1)

        const {daiContractWithSignerPool} = getMainContract()  

        await daiContractWithSignerPool.change_fee(adr,feeBigInt);   

        return true
    } catch (err) {
        console.info('err in transaction', err);    

        return false
    }
}
  
export async function changeCreatorFee(adr:string,fee:number) : Promise<boolean> {  
    try {
		const feeBigInt = number_to_bigNumber(fee,1)

        const {daiContractWithSignerPool} = getMainContract()  

        await daiContractWithSignerPool.change_creator_fee(adr,feeBigInt);

        return true
      } catch (err) {

        return false
    }
}
  
export async function changeCreator(adr:string,creator:string) : Promise<boolean> {  
    try {
        const {daiContractWithSignerPool} = getMainContract()  

        await daiContractWithSignerPool.creator(adr,creator);

        return true
      } catch (err) {
        console.info('err in transaction', err);

        return false
    }
}
  
export async function deleteCollectionSmart(adr:string) : Promise<boolean> {  
    try {
        const {daiContractWithSignerPool} = getMainContract()  

        await daiContractWithSignerPool.delete_collection(adr);

        return true
      } catch (err) {
        console.info('err in transaction', err);

        return false
    }
}